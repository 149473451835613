import PagingParams, {
    getNewPagingParams
} from '@ashiteam/base-react-lib/dist/models/Paging/PagingParams';

interface BaseParams extends PagingParams {
  orderBy: string;
  mine?: boolean;
  nameLike?: string;
}

export const getNewBaseParams = (
  nameLike?: string,
  orderBy?: string,
  mine?: boolean,
  pageNumber: number = 1,
  pageSize: number = 10
): BaseParams => {
  return {
    ...getNewPagingParams(pageNumber, pageSize),
    nameLike: nameLike,
    orderBy: orderBy ? orderBy : '',
    mine: mine,
  };
};

export default BaseParams;
