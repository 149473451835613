import BaseParams, { getNewBaseParams } from './BaseParams';

interface AlbumEventParams extends BaseParams {
  ownerId?: number;
}

export const getNewAlbumEventParams = (
  nameLike?: string,
  ownerId?: number,
  mine?: boolean,
  pageNumber: number = 1,
  pageSize: number = 10
): AlbumEventParams => {
  return {
    ...getNewBaseParams(nameLike, undefined, mine, pageNumber, pageSize),
    ownerId: ownerId,
    nameLike: nameLike,
  };
};

export default AlbumEventParams;
