import User, { getNewUser } from '@ashiteam/base-react-lib/dist/models/User/User';

import AlbumEventForAlbum, { getNewAlbumEventForAlbum } from './AlbumEventForAlbum';
import AlbumEventLocationForList from './AlbumEventLocationForList';
import CategoryForList, { getNewCategoryForList } from './CategoryForList';

interface AlbumEventForList extends AlbumEventForAlbum {
  owner: User;
  cat: CategoryForList;
  locations: AlbumEventLocationForList[];
}

export const getNewAlbumEventForList = (): AlbumEventForList => {
  return {
    ...getNewAlbumEventForAlbum(),
    owner: getNewUser(),
    cat: getNewCategoryForList(),
    locations: [],
  };
};

export default AlbumEventForList;
