import LocationMapData, { getNewLocationMapData } from './LocationMapData';
import LocationNameData, { getNewLocationNameData } from './LocationNameData';

interface LocationBase {
  id: number;
  name: string;
  dateCreated: Date;
  dateLastUpdated: Date;
  mapUrl?: string;
  mapImageUrl: string;
  aboutUrl?: string;
  aboutThumbUrl?: string;
  nameData?: LocationNameData;
  mapData?: LocationMapData;
}

export const getNewLocationBase = (
  id?: number,
  name?: string,
  aboutUrl?: string,
  lat?: number,
  lon?: number
): LocationBase => {
  return {
    id: id ?? 0,
    name: name ?? '',
    aboutUrl: aboutUrl,
    nameData: getNewLocationNameData(name),
    mapData: getNewLocationMapData(lat, lon),
    dateCreated: new Date(Date.now()),
    dateLastUpdated: new Date(Date.now()),
    mapImageUrl: '',
  };
};

export default LocationBase;
