import User, { getNewUser } from '@ashiteam/base-react-lib/dist/models/User/User';

import AlbumBase, { getNewAlbumBase } from './AlbumBase';
import AlbumEventForAlbum, { getNewAlbumEventForAlbum } from './AlbumEventForAlbum';
import CategoryForList, { getNewCategoryForList } from './CategoryForList';

interface AlbumBaseEx extends AlbumBase {
  owner: User;
  event?: AlbumEventForAlbum;
  cat: CategoryForList;
  mainPhotoUrl?: string;
  photoCount: number;
  videoCount: number;
  canEdit: boolean;
  secureMainPhotoUrl?: string;
}

export const getNewAlbumBaseEx = (): AlbumBaseEx => {
  const album: AlbumBaseEx = {
    ...getNewAlbumBase(),
    owner: getNewUser(),
    event: getNewAlbumEventForAlbum(),
    cat: getNewCategoryForList(),
    mainPhotoUrl: undefined,
    photoCount: 0,
    videoCount: 0,
    canEdit: true,
    secureMainPhotoUrl: '',
  };

  return album;
};

export default AlbumBaseEx;
