import axios, { AxiosError, AxiosResponse } from 'axios';
import { toast } from 'react-toastify';

import PaginatedResult from '@ashiteam/base-react-lib/dist/models/Paging/PaginatedResult';

import GlobalHelper from '../lib/GlobalHelper';
import { store } from '../stores/Store';

const parseJwt = (token: string) => {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
};

let axiosInitialized = false;

const initAxios = () => {
  if (!axiosInitialized) {
    console.log('initAxios');
    axios.defaults.baseURL = GlobalHelper.getMainApiUrl(); // process.env.REACT_APP_API_URL;

    axios.interceptors.request.use((config) => {
      // console.log(axios.defaults.baseURL);
      const token = store.userStore.token;
      if (token) {
        const json = parseJwt(token);
        const exp = json.exp;
        if (Date.now() < exp * 1000) {
          if (config.headers) {
            (config.headers as any).Authorization = `Bearer ${token}`;
          }
        } else {
          console.log('Token has expired.');
          store.userStore.setToken(null);
        }
      }
      return config;
    });

    axios.interceptors.response.use(
      async (response) => {
        const pagination = response.headers['pagination'];
        if (!!pagination) {
          // console.log('responsea', response);
          // console.log('pagination', pagination);
          // console.log('response.data before', response.data);
          response.data = new PaginatedResult(response.data, JSON.parse(pagination));
          // console.log('response.data after', response.data);
          return response as AxiosResponse<PaginatedResult<any>>;
        }
        if (
          typeof response.data === 'string' &&
          response.data.startsWith('<!doctype html><html lang="en"><head><meta charset="utf-8"/>')
        ) {
          console.log(response.data);
          // store.userStore.setToken(null);
          // response.status = 401;
          // throw new Error('Access Denied');
        }
        return response;
      },
      (error: AxiosError<any, any>) => {
        // console.log(error);
        if (error.code === 'ERR_NETWORK') {
          throw error;
        }
        if (error.code === 'ERR_CANCELED') {
          throw error;
        }

        const { data, status, config } = error.response!;
        switch (status) {
          case 400:
            if (typeof data === 'string') {
              console.log(data);
              toast.error(data);
            }
            if (config.method === 'get' && data.errors?.hasOwnProperty('id')) {
              // navigate('/not-found');
              console.log('Item not found on the server.');
              toast.error('Item not found on the server.');
            }
            if (data.errors) {
              const modalStateErrors: any[] = [];
              for (const key in data.errors) {
                if (data.errors[key]) {
                  modalStateErrors.push(data.errors[key]);
                }
              }
              throw modalStateErrors.flat();
            }
            break;
          case 401:
            console.log('came to Unauthorized');
            toast.error('You do not have access.');
            break;
          case 404:
            console.log('Item not found on the server.');
            toast.error('Item not found on the server.');
            // navigate('/not-found');
            break;
          case 500:
            // toast.error('Server Error. ' + store.userStore.setServerError.name);
            // store.commonStore.setServerError(data);
            break;
        }
        throw error;
      }
    );

    axiosInitialized = true;
  }
};

// const responseBody = <T>(response: AxiosResponse<T>) => response?.data;

// const request = {
//   get: <T>(url: string) => axios.get<T>(url).then(responseBody),
//   post: <T>(url: string, body: {}) => axios.post<T>(url, body).then(responseBody),
//   postWithConfig: <T>(url: string, body: {}, config: {}) => axios.post<T>(url, body, config).then(responseBody),
//   put: <T>(url: string, body: {}) => axios.put<T>(url, body).then(responseBody),
//   del: <T>(url: string) => axios.delete<T>(url).then(responseBody),
// };

// const Account = {
//   current: () => request.get<UserSigninRetData>('/auth/me'),
//   login: (user: UserForLogin) => request.post<UserSigninRetData>('/auth/signin', user),
//   logout: () => request.post<string>('/auth/signout', {}),
//   setPreferences: (id: number, preferences: UserPreferences) => request.post('/auth/preferences/' + id, preferences),
// };

// const Albums = {
//   allAlbums: (params: AlbumParams) => axios.get<PaginatedResult<AlbumForList[]>>('/album/albums', { params }),
//   getMainImage: (album: AlbumBaseEx) =>
//     album.mainPhotoUrl
//       ? request.get<string>(album.mainPhotoUrl.substring(4) + '/base64/345')
//       : '/images/image-missing-md.png',
//   getPhoto: (id: number, size: number) => request.get<string>(`/album/photos/${id}/image/${size}`),
//   getPhotoBase64: (id: number, size: number) => request.get<string>(`/album/photos/${id}/image/base64/${size}`),
//   getPhotoBase64FromUrl: (url: string) => request.get<string>(url),
//   getPhotoFromUrl: (url: string) => request.get<Blob>(url),
// };

// const agent = {
//   Account,
//   Albums,
// };

// export default agent;

export default initAxios;
