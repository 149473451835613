interface AlbumBase {
  id: number;
  name?: string;
  description?: string;
  ownerId: number;
  isPublic: boolean;
  isEncrypted: boolean;
  eventId?: number;
  fromDate?: Date;
  toDate?: Date;
  dateCreated: string;
  dateLastUpdated: Date;
  catId: number;
}

export const getNewAlbumBase = (): AlbumBase => {
  return {
    id: 0,
    name: undefined,
    description: undefined,
    ownerId: 0,
    isPublic: false,
    isEncrypted: false,
    eventId: 0,
    fromDate: undefined,
    toDate: undefined,
    dateCreated: new Date(Date.now()).toISOString(),
    dateLastUpdated: new Date(Date.now()),
    catId: 0,
  };
};

export default AlbumBase;
