interface LocationNameData {
  id: number;
  name: string;
  countryRegion?: string;
  locality?: string;
}

export const getNewLocationNameData = (name?: string): LocationNameData => {
  return {
    id: 0,
    name: name ?? '',
  };
};

export default LocationNameData;
