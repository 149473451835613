import initAxios from '../api/agent';
import AlbumEvent, {
    getAlbumEventForEditFromAlbumEvent, getNewAlbumEvent
} from '../models/AlbumEvent';
import AlbumEventForEdit from '../models/AlbumEventForEdit';
import AlbumEventForList from '../models/AlbumEventForList';
import AlbumEventDetailParams, {
    getNewAlbumEventDetailParams
} from '../models/params/AlbumEventDetailParams';
import AlbumEventParams, { getNewAlbumEventParams } from '../models/params/AlbumEventParams';
import BaseStore from './BaseStore';

// implements ItemsStore<AlbumEventForList, AlbumEventParams>, IItemStore<AlbumEvent, AlbumEventDetailParams>
export default class EventStore extends BaseStore<
  AlbumEventForList,
  AlbumEventParams,
  AlbumEvent,
  AlbumEventDetailParams,
  AlbumEvent,
  AlbumEventForEdit
> {
  readonly itemsUrlPart = '/album/events';
  readonly itemUrlPart = '/album/event/';

  constructor() {
    super(
      getNewAlbumEventParams,
      getNewAlbumEventDetailParams,
      getNewAlbumEvent,
      getNewAlbumEvent,
      getAlbumEventForEditFromAlbumEvent
    );
    initAxios();
  }
}
