import AlbumEventAccess from './AlbumEventAccess';
import AlbumEventForEdit from './AlbumEventForEdit';
import AlbumEventForList, { getNewAlbumEventForList } from './AlbumEventForList';
import AlbumForList from './AlbumForList';

interface AlbumEvent extends AlbumEventForList {
  albums: AlbumForList[];
  accessUsers: AlbumEventAccess[];
}

export const getNewAlbumEvent = (): AlbumEvent => {
  return {
    ...getNewAlbumEventForList(),
    albums: [],
    accessUsers: [],
  };
};

export const getAlbumEventForEditFromAlbumEvent = (albumEvent: AlbumEvent, ownerId: number): AlbumEventForEdit => {
  const albumEventForEdit: AlbumEventForEdit = {
    id: albumEvent.id,
    name: albumEvent.name,
    description: albumEvent.description,
    isPublic: albumEvent.isPublic,
    dateCreated: albumEvent.dateCreated,
    canEdit: albumEvent.canEdit,
    ownerId: albumEvent.ownerId > 0 ? albumEvent.ownerId : ownerId,
    catId: +albumEvent.catId,
    fromDate: albumEvent.fromDate,
    toDate: albumEvent.toDate,
    autoDates: albumEvent.autoDates,
    autoLocations: albumEvent.autoLocations,
    locations: [],
    accessUsers: [],
    dateLastUpdated: new Date(Date.now()),
  };

  albumEvent.locations.forEach((x) => {
    if (+x.locationId !== 0) {
      x.locationId = +x.locationId;
      x.albumEventId = +x.albumEventId;
      albumEventForEdit.locations.push(x);
    }
  });

  albumEvent.accessUsers.forEach((x) => {
    if (+x.userId !== 0) {
      x.userId = +x.userId;
      x.albumEventId = +x.albumEventId;
      albumEventForEdit.accessUsers.push(x);
    }
  });

  return albumEventForEdit;
};

export default AlbumEvent;
