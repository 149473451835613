import CategoryBase, { getNewCategoryBase } from './CategoryBase';

interface CategoryForList extends CategoryBase {
  eventCount: number;
  albumCount: number;
}

export const getNewCategoryForList = (name: string = '') => {
  const category: CategoryForList = { ...getNewCategoryBase(name), eventCount: 0, albumCount: 0 };
  return category;
};

export default CategoryForList;
