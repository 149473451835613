import BaseParams, { getNewBaseParams } from './BaseParams';

interface AlbumParams extends BaseParams {
  ownerId?: number;
  fromDate?: Date;
  toDate?: Date;
}

export const getNewAlbumParams = (
  nameLike?: string,
  orderBy?: string,
  ownerId?: number,
  mine?: boolean,
  pageNumber: number = 1,
  pageSize: number = 10
) => {
  return {
    ...getNewBaseParams(nameLike, orderBy, mine, pageNumber, pageSize),
    ownerId: ownerId,
  };
};

export default AlbumParams;
