interface LocationParams {
  albums?: boolean;
  events?: boolean;
  photos?: boolean;
  albumPhotos?: boolean;
}

export const getNewLocationParams = (
  albums: boolean = true,
  events: boolean = true,
  photos: boolean = true,
  albumPhotos: boolean = false
): LocationParams => {
  return {
    albums: albums,
    events: events,
    photos: photos,
    albumPhotos: albumPhotos,
  };
};

export default LocationParams;
