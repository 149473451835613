import User from '@ashiteam/base-react-lib/dist/models/User/User';

export enum EThumbnailSize {
  defaultSize = 0,
  large = 1,
  medium = 2,
  small = 3,
}

export enum EAdvanceDataEntry {
  ShowWhenAsked = 0,
  AlwaysShow = 1,
  NeverShow = 2,
}

export interface UserPreferences {
  id: number;
  viewAsTable?: boolean;
  hideEdits?: boolean;
  thumbnailSize?: EThumbnailSize;
  advanceDataEntry?: EAdvanceDataEntry;
}

export class UserPreferencesClass {
  data: UserPreferences;

  constructor(data: UserPreferences) {
    this.data = data;
  }

  get thumbnailSizeToUse() {
    return this.data.thumbnailSize && +this.data.thumbnailSize !== +EThumbnailSize.defaultSize
      ? this.data.thumbnailSize
      : EThumbnailSize.medium;
  }

  get advanceDataEntryToUse() {
    return this.data.advanceDataEntry ? this.data.advanceDataEntry : EAdvanceDataEntry.ShowWhenAsked;
  }

  get thumbnailSizeString() {
    return this.thumbnailSizeToUse.toString();
  }

  set thumbnailSizeString(value: string) {
    this.data.thumbnailSize = +value;
  }

  get thumbnailSizeInt() {
    return this.thumbnailSizeToUse;
  }

  set thumbnailSizeInt(value: number) {
    this.data.thumbnailSize = +value;
  }
}

export interface UserSigninRetData {
  token?: string;
  preferences: UserPreferences;
  user: User;
}
