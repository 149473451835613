import { createContext, useContext } from 'react';

import AdminActionStore from './AdminActionStore';
import AlbumStore from './AlbumStore';
import AlbumUserStore from './AlbumUserStore';
import CategoryStore from './CategoryStore';
import EventStore from './EventStore';
import ImageStore from './ImageStore';
import LocationStore from './LocationStore';
import NavStore from './NavStore';
import PhotoDownloadStore from './PhotoDownloadStore';
import PhotoUploadStore from './PhotoUploadStore';
import UserStore from './UserStore';

interface Store {
  navStore: NavStore;
  userStore: UserStore;
  albumStore: AlbumStore;
  eventStore: EventStore;
  categoryStore: CategoryStore;
  locationStore: LocationStore;
  imageStore: ImageStore;
  albumUserStore: AlbumUserStore;
  photoUploadStore: PhotoUploadStore;
  photoDownloadStore: PhotoDownloadStore;
  adminActionStore: AdminActionStore;
}

export const store: Store = {
  navStore: new NavStore(),
  userStore: new UserStore(),
  albumStore: new AlbumStore(),
  eventStore: new EventStore(),
  categoryStore: new CategoryStore(),
  locationStore: new LocationStore(),
  imageStore: new ImageStore(),
  albumUserStore: new AlbumUserStore(),
  photoUploadStore: new PhotoUploadStore(),
  photoDownloadStore: new PhotoDownloadStore(),
  adminActionStore: new AdminActionStore(),
};

export const StoreContext = createContext(store);

export const useStore = () => {
  return useContext(StoreContext);
};
