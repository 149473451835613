interface AlbumDetailParams {
  photos: boolean;
  event: boolean;
}

export const getAlbumDetailParams = (photos: boolean = true, event: boolean = true): AlbumDetailParams => {
  return {
    photos: photos,
    event: event,
  };
};

export default AlbumDetailParams;
