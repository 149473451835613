import { makeAutoObservable, runInAction } from 'mobx';

import GlobalHelper from '../lib/GlobalHelper';
import Photo from '../models/Photo';

export interface DownloadPhoto extends Photo {
  downloaded: boolean;
}

export default class PhotoDownloadStore {
  downloading: boolean = false;
  photos: DownloadPhoto[] = [];
  original: boolean = false;
  zipAndDownload: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  get haveAnySelected() {
    return this.photos.some((x) => x.isSelected);
  }

  get haveAnyUnselected() {
    return this.photos.some((x) => !x.isSelected);
  }

  get allDownloaded() {
    if (!this.downloading) {
      return false;
    }
    const haveMoreToDownload = this.photos.some((x) => x.isSelected && !x.downloaded);
    return !haveMoreToDownload;
  }

  get selectedPhotos() {
    return this.photos.filter((x) => x.isSelected);
  }

  get photoToDownload() {
    if (!this.downloading || this.zipAndDownload) {
      return undefined;
    }
    return this.photos.find((x) => x.isSelected && !x.downloaded);
  }

  get totalSelected() {
    return this.photos.filter((x) => x.isSelected).length;
  }

  get totalDownloaded() {
    return this.photos.filter((x) => x.downloaded).length;
  }

  setDownloading = (value: boolean) => {
    if (value && !this.haveAnySelected) {
      value = false;
    }
    this.downloading = value;
  };

  private photoToDownloadPhoto = (photo: Photo): DownloadPhoto => {
    return { ...photo, downloaded: false };
  };

  setDownloadPhotos = (photos: Photo[]) => {
    this.photos = photos.map((x) => this.photoToDownloadPhoto(x));
    this.selectAllPhotos(false);
  };

  selectPhoto = (id: number, select: boolean) => {
    const photo = this.photos.find((x) => x.id === id);
    if (!!photo) {
      photo.isSelected = select;
    } else {
      console.log('Photo not found', id, this.photos);
    }
    this.clearAllDownloadedFlags();
  };

  selectAllPhotos = (select: boolean) => {
    this.photos.forEach((x) => (x.isSelected = select));
    this.clearAllDownloadedFlags();
  };

  markPhotoAsDownloaded = (id: number) => {
    const photo = this.photos.find((x) => x.id === id);
    if (!!photo) {
      setTimeout(() => {
        runInAction(() => (photo.downloaded = true));
        console.log('Downloaded ' + id);
        if (this.allDownloaded) {
          this.setDownloading(false);
          this.clearAllDownloadedFlags();
          console.log('All Downloaded');
        }
      }, 100);
    } else {
      console.log('Photo not found', id, this.photos);
    }
  };

  clearAllDownloadedFlags = () => {
    this.photos.forEach((x) => (x.downloaded = false));
  };

  getDownloadUrl = (id: number) => {
    return this.zipAndDownload
      ? undefined
      : GlobalHelper.getMainApiUrl() + `/album/photos/${id}/download/${this.original ? 'original' : 'normal'}`;
  };

  getZipAndDownloadUrl = () => {
    if (!this.zipAndDownload) {
      return undefined;
    }

    const param = this.photos
      .filter((x) => x.isSelected)
      .map((x) => x.id)
      .join(',');

    return (
      GlobalHelper.getMainApiUrl() + `/album/photos/download/zip/${this.original ? 'full' : 'normal'}/${btoa(param)}`
    );
  };

  setOriginal = (value: boolean) => {
    this.original = value;
  };

  setZipAndDownload = (value: boolean) => {
    this.zipAndDownload = value;
  };
}
