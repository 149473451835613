import { makeAutoObservable, runInAction } from 'mobx';

import MenuItemData from '@ashiteam/base-react-lib/dist/models/MenuItemData/MenuItemData';

import getAppMenuData, { userNameMenuTag } from '../lib/AppMenuData';
import { store } from './Store';

export default class NavStore {
  loading = false;
  appLoaded = false;
  drawerOpen = false;
  activeMenu: MenuItemData[] = [];
  topBarMenuItems: MenuItemData[] = [];
  leftDrawerMenuItems: MenuItemData[] = [];
  isDrawerMode: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  openDrawer = () => {
    this.drawerOpen = true;
  };

  closeDrawer = () => {
    this.drawerOpen = false;
  };

  toggleDrawer = () => {
    this.drawerOpen = !this.drawerOpen;
  };

  setIsDrawerMode = (isDrawerMode: boolean) => {
    if (isDrawerMode !== this.isDrawerMode) {
      this.isDrawerMode = isDrawerMode;
      this.loadActiveMenu(true);
    }
  };

  loadActiveMenu = (reload: boolean) => {
    // console.log('loadActiveMenu', reload, this.activeMenu.length, this.loading, store.userStore.isLoggedIn());
    if ((reload || this.activeMenu.length === 0) && !this.loading) {
      this.loading = true;
      try {
        const isLoggedIn = store.userStore.isLoggedIn();
        const isAdmin = store.userStore.isAdmin();
        const name = store.userStore.user?.knownAs;
        const menuItems: MenuItemData[] = [];
        // console.log('loadActiveMenu - name', name, isAdmin);
        getAppMenuData().forEach((menuItem) => {
          if (
            menuItem.showWhen === 'always' ||
            (menuItem.showWhen === 'loggedIn' && isLoggedIn) ||
            (menuItem.showWhen === 'loggedOut' && !isLoggedIn) ||
            (menuItem.showWhen === 'isAdmin' && isAdmin)
          ) {
            if (isLoggedIn) {
              menuItem.title = menuItem.title.replace(userNameMenuTag, name ?? '');
            }
            menuItems.push(menuItem);
          }
        });

        runInAction(() => {
          this.activeMenu = menuItems;
          this.topBarMenuItems = this.getTopBarMenuItems();
          this.leftDrawerMenuItems = this.isDrawerMode ? menuItems : [];
          // console.log('loadActiveMenu loaded', this.topBarMenuItems.length);
        });
      } catch (error) {
        console.log('ERROR loadActiveMenu', error);
      } finally {
        runInAction(() => (this.loading = false));
      }
    }
  };

  private getTopBarMenuItems = () => {
    return this.activeMenu.filter((x) => {
      if (x.alwaysInTopBar) {
        return true;
      }
      return !this.isDrawerMode;
    });
  };

  setAppLoaded = () => {
    this.appLoaded = true;
  };
}
