import AlbumForEdit, { getNewAlbumForEdit } from './AlbumForEdit';
import Photo from './Photo';

interface Album extends AlbumForEdit {
  photos: Photo[];
}

export const getNewAlbum = (): Album => {
  return {
    ...getNewAlbumForEdit(),
    photos: [],
  };
};

export const getAlbumEditFromAlbum = (item: Album): AlbumForEdit => {
  const editItem = { ...item };
  editItem.photos = [];
  return editItem;
};

export default Album;
