import axios, { AxiosProgressEvent } from 'axios';
import { makeAutoObservable, runInAction } from 'mobx';
import { toast } from 'react-toastify';

import Album from '../models/Album';
import Photo from '../models/Photo';
import { store } from './Store';

export interface PhotoUploadData {
  id: number;
  file: File;
  uploadedBytes: number;
  uploaded: boolean;
  uploadPercent: number;
  photoCreated: boolean;
  done: boolean;
  photo?: Photo;
}

export default class PhotoUploadStore {
  uploading: boolean = false;
  files: PhotoUploadData[] = [];
  abortController = new AbortController();
  allUploaded: boolean = false;
  working: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  get uploadToAlbum(): Album {
    return store.albumStore.editItem;
  }

  clearUploadItems = () => {
    this.uploading = false;
    this.files = [];
  };

  addFile = (file: File) => {
    this.files.push({
      id: this.files.length + 1,
      file: file,
      uploaded: false,
      done: false,
      uploadPercent: 0,
      uploadedBytes: 0,
      photoCreated: false,
    });
  };

  getUploadPercent = (id: number) => {
    return this.files.find((x) => x.id === id)?.uploadPercent ?? 0;
  };

  setUploadedAmt = (id: number, amount: number) => {
    const fileData = this.files.find((x) => x.id === id);
    if (fileData) {
      fileData.uploadedBytes = amount;
      fileData.uploadPercent = Math.min((fileData.uploadedBytes * 100) / fileData.file.size, 100);
      fileData.uploaded = fileData.uploadPercent >= 100;
      if (fileData.uploaded) {
        this.updateAllUploaded();
      }
    }
  };

  startUploading = () => {
    this.allUploaded = false;
    this.uploading = true;
    this.working = true;
    this.abortController = new AbortController();
    this.files.filter((x) => !x.uploaded).forEach((file) => this.uploadPhotoBinary(file));
  };

  stopUploading = () => {
    this.uploading = false;
    this.abortController.abort();
    this.files.forEach((file) => {
      if (!file.uploaded) {
        this.setUploadedAmt(file.id, 0);
      }
      file.done = true;
    });
    this.updateAllUploaded();
  };

  isUploadingFile = (id: number) => {
    return this.uploading && !this.hasUploadedFile(id);
  };

  hasUploadedFile = (id: number) => {
    return this.files.find((x) => x.id === id)?.uploaded ?? false;
  };

  updateAllUploaded() {
    const index = this.files.findIndex((x) => !x.done);
    const done = index < 0;
    if (done) {
      runInAction(() => {
        this.uploading = false;
      });
      axios
        .post<Photo>(`/album/album/${this.uploadToAlbum.id}/uploadphotos/done`, {})
        .then((resp) => {
          console.log('DONE ALL - uploadphotos/done', resp);
          this.files.filter((x) => !!x.photo?.isVideo).forEach((x) => store.albumStore.addPhoto(x.photo!));
          this.clearUploadItems();
          store.albumStore.fixMissingMainPhoto();
        })
        .finally(() => runInAction(() => (this.working = false)));
    }
    this.allUploaded = done;
  }

  private uploadPhotoFileImpl = (
    formData: FormData,
    signal: AbortSignal,
    onUploadProgress: (progressEvent: AxiosProgressEvent) => void
  ) => {
    return axios.post<Photo>(`/album/album/${this.uploadToAlbum.id}/uploadphoto`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress,
      signal,
    });
  };

  private uploadPhotoBinary = (file: PhotoUploadData) => {
    let formData = new FormData();

    formData.append('fileBinary', file.file);

    this.uploadPhotoFileImpl(formData, this.abortController.signal, (progressEvent) => {
      // const progressRaw = (progressEvent.loaded * 100) / (progressEvent.total ?? 1);
      // const progress = Math.round(progressRaw);
      // console.log(
      //   'progress',
      //   progressEvent.bytes,
      //   progressEvent.loaded,
      //   progressEvent.total,
      //   progressEvent.progress,
      //   progressRaw
      // );
      this.setUploadedAmt(file.id, progressEvent.loaded);
    })
      .then((response) => {
        // store.shareItemStore.addBinaryItem(newItem);
        // console.log(response.data);
        if (!response.data.isVideo) {
          store.albumStore.addPhoto(response.data);
        }
        runInAction(() => {
          file.photoCreated = true;
          file.done = true;
          file.photo = response.data;
        });
        const doneCount = this.files.filter((x) => x.photoCreated).length;
        console.log('file.uploaded', file.id, `Done ${doneCount} of ${this.files.length}`);
        this.updateAllUploaded();
      })
      .catch((error) => {
        console.log(error);
        toast.error(error);
        runInAction(() => {
          file.done = true;
        });
      });
  };
}
