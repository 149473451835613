import { observer } from 'mobx-react-lite';
import React, { Suspense, useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';

import AppNav from '@ashiteam/mui-react-lib/dist/components/Nav/AppNav';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';

import ComponentLoading from './components/ComponentLoading';
import GlobalHelper from './lib/GlobalHelper';
import PhotoHelper from './lib/PhotoHelper';
import { useStore } from './stores/Store';

const AlbumsPage = React.lazy(() => import('./pages/Albums/AlbumsPage'));
const CategoriesPage = React.lazy(() => import('./pages/Categories/CategoriesPage'));
const EventsPage = React.lazy(() => import('./pages/Events/EventsPage'));
const LocationsPage = React.lazy(() => import('./pages/Locations/LocationsPage'));
const NotFoundPage = React.lazy(() => import('./pages/NotFoundPage'));
const EditProfilePage = React.lazy(() => import('./pages/User/EditProfilePage'));
const AlbumPage = React.lazy(() => import('./pages/Albums/AlbumPage'));
const AlbumDownloadPage = React.lazy(() => import('./pages/Albums/AlbumDownloadPage'));

const AdminActionsPage = React.lazy(() => import('./pages/Admin/AdminActionsPage'));
const DuplicatePhotosPage = React.lazy(() => import('./pages/Admin/DuplicatePhotosPage'));
const EditAlbumPage = React.lazy(() => import('./pages/Albums/EditAlbumPage'));
const NewAlbumPage = React.lazy(() => import('./pages/Albums/NewAlbumPage'));
const CategoryPage = React.lazy(() => import('./pages/Categories/CategoryPage'));
const EditCategoryPage = React.lazy(() => import('./pages/Categories/EditCategoryPage'));
const NewCategoryPage = React.lazy(() => import('./pages/Categories/NewCategoryPage'));
const EditEventPage = React.lazy(() => import('./pages/Events/EditEventPage'));
const EventPage = React.lazy(() => import('./pages/Events/EventPage'));
const NewEventPage = React.lazy(() => import('./pages/Events/NewEventPage'));
const EditLocationPage = React.lazy(() => import('./pages/Locations/EditLocationPage'));
const LocationDownloadPage = React.lazy(() => import('./pages/Locations/LocationDownloadPage'));
const LocationPage = React.lazy(() => import('./pages/Locations/LocationPage'));
const NewLocationPage = React.lazy(() => import('./pages/Locations/NewLocationPage'));

const SignInPage = React.lazy(() => import('./pages/SignInPage'));

function App() {
  const theme = useTheme();
  const isDrawerMode = !useMediaQuery(theme.breakpoints.up('sm'));
  const [initialized, setInitialized] = useState(false);
  const { userStore, navStore } = useStore();
  const { token, loadUser } = userStore;
  const {
    topBarMenuItems,
    leftDrawerMenuItems,
    activeMenu,
    drawerOpen,
    closeDrawer,
    toggleDrawer,
    loadActiveMenu,
    setAppLoaded,
    setIsDrawerMode,
  } = navStore;

  useEffect(() => {
    if (initialized) {
      setIsDrawerMode(isDrawerMode);
    }
  }, [initialized, isDrawerMode, setIsDrawerMode]);

  useEffect(() => {
    if (initialized) {
      if (token) {
        loadUser().finally(() => {
          loadActiveMenu(false);
          setAppLoaded();
        });
      } else {
        loadActiveMenu(false);
        setAppLoaded();
      }
    }
  }, [initialized, loadActiveMenu, loadUser, setAppLoaded, token]);

  useEffect(() => {
    console.log('calling GlobalHelper.initialize');
    GlobalHelper.initializeAsync(theme, window.location.href).finally(() => setInitialized(true));
    console.log('calling PhotoHelper.initialize', window.location.href);
    PhotoHelper.initialize(window.location.href);
  }, [theme]);

  document.body.style.overflowY = 'scroll';

  if (!navStore.appLoaded || activeMenu.length === 0) {
    return <></>;
  }

  return (
    <div style={{ textAlign: 'left' }}>
      <CssBaseline />
      <AppNav
        containerMaxWidth={GlobalHelper.containerMaxWidth}
        logoImgSrc={GlobalHelper.logoImgSrc}
        logoImgSrcSet={GlobalHelper.logoImgSrcSet}
        appName={GlobalHelper.appName}
        drawerOpen={drawerOpen}
        closeDrawer={closeDrawer}
        toggleDrawer={toggleDrawer}
        topMenuItems={topBarMenuItems}
        leftDrawerMenuItems={leftDrawerMenuItems}
      />
      <Container maxWidth={GlobalHelper.containerMaxWidth} sx={{ mt: 10 }}>
        <Box sx={{ px: 0, pt: 0 }}>
          <Routes>
            <Route
              path='/'
              element={
                <Suspense fallback={<ComponentLoading />}>
                  <AlbumsPage />
                </Suspense>
              }
            />
            <Route
              path='/events'
              element={
                <Suspense fallback={<ComponentLoading />}>
                  <EventsPage />
                </Suspense>
              }
            />
            <Route
              path='/categories'
              element={
                <Suspense fallback={<ComponentLoading />}>
                  <CategoriesPage />
                </Suspense>
              }
            />
            <Route
              path='/locations'
              element={
                <Suspense fallback={<ComponentLoading />}>
                  <LocationsPage />
                </Suspense>
              }
            />
            <Route
              path='/member/edit'
              element={
                <Suspense fallback={<ComponentLoading />}>
                  <EditProfilePage />
                </Suspense>
              }
            />
            <Route
              path='/member/editpref'
              element={
                <Suspense fallback={<ComponentLoading />}>
                  <EditProfilePage />
                </Suspense>
              }
            />
            <Route
              path='signin'
              element={
                <Suspense fallback={<ComponentLoading />}>
                  <SignInPage />
                </Suspense>
              }
            />
            <Route
              path='album/new'
              element={
                <Suspense fallback={<ComponentLoading />}>
                  <NewAlbumPage />
                </Suspense>
              }
            />
            <Route
              path='album/edit/:id'
              element={
                <Suspense fallback={<ComponentLoading />}>
                  <EditAlbumPage />
                </Suspense>
              }
            />
            <Route
              path='album/:id'
              element={
                <Suspense fallback={<ComponentLoading />}>
                  <AlbumPage />
                </Suspense>
              }
            />
            <Route
              path='album/download/:id'
              element={
                <Suspense fallback={<ComponentLoading />}>
                  <AlbumDownloadPage />
                </Suspense>
              }
            />
            <Route
              path='event/new'
              element={
                <Suspense fallback={<ComponentLoading />}>
                  <NewEventPage />
                </Suspense>
              }
            />
            <Route
              path='event/edit/:id'
              element={
                <Suspense fallback={<ComponentLoading />}>
                  <EditEventPage />
                </Suspense>
              }
            />
            <Route
              path='event/:id'
              element={
                <Suspense fallback={<ComponentLoading />}>
                  <EventPage />
                </Suspense>
              }
            />
            <Route
              path='category/new'
              element={
                <Suspense fallback={<ComponentLoading />}>
                  <NewCategoryPage />
                </Suspense>
              }
            />
            <Route
              path='category/edit/:id'
              element={
                <Suspense fallback={<ComponentLoading />}>
                  <EditCategoryPage />
                </Suspense>
              }
            />
            <Route
              path='category/:id'
              element={
                <Suspense fallback={<ComponentLoading />}>
                  <CategoryPage />
                </Suspense>
              }
            />
            <Route
              path='location/new'
              element={
                <Suspense fallback={<ComponentLoading />}>
                  <NewLocationPage />
                </Suspense>
              }
            />
            <Route
              path='location/edit/:id'
              element={
                <Suspense fallback={<ComponentLoading />}>
                  <EditLocationPage />
                </Suspense>
              }
            />
            <Route
              path='location/:id'
              element={
                <Suspense fallback={<ComponentLoading />}>
                  <LocationPage />
                </Suspense>
              }
            />
            <Route
              path='location/download/:id'
              element={
                <Suspense fallback={<ComponentLoading />}>
                  <LocationDownloadPage />
                </Suspense>
              }
            />
            <Route
              path='admin'
              element={
                <Suspense fallback={<ComponentLoading />}>
                  <AdminActionsPage />
                </Suspense>
              }
            />
            <Route
              path='admin/duplicatephotos'
              element={
                <Suspense fallback={<ComponentLoading />}>
                  <DuplicatePhotosPage />
                </Suspense>
              }
            />
            <Route
              path='*'
              element={
                <Suspense fallback={<ComponentLoading />}>
                  <NotFoundPage />
                </Suspense>
              }
            />
          </Routes>
        </Box>
      </Container>
    </div>
  );
}

export default observer(App);
