import initAxios from '../api/agent';
import Location, { getLocationBaseFromLocation, getNewLocation } from '../models/Location';
import LocationBase from '../models/LocationBase';
import LocationForList from '../models/LocationForList';
import LocationPagedParams, {
    getNewLocationPagedParams
} from '../models/params/LocationPagedParams';
import LocationParams, { getNewLocationParams } from '../models/params/LocationParams';
import BaseStore from './BaseStore';

export default class LocationStore extends BaseStore<
  LocationForList,
  LocationPagedParams,
  Location,
  LocationParams,
  Location,
  LocationBase
> {
  readonly itemsUrlPart = '/album/locations';
  readonly itemUrlPart = '/album/location/';

  constructor() {
    super(getNewLocationPagedParams, getNewLocationParams, getNewLocation, getNewLocation, getLocationBaseFromLocation);
    initAxios();
  }
}
