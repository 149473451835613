import GlobalHelper from './GlobalHelper';

class PhotoHelper {
  static get missingImageUrl() {
    return '/images/image-missing-md.png';
  }

  static get loadingImageUrl() {
    return '/images/loading.png';
  }

  static get defaultThumbSize() {
    return 120;
  }

  static getImgSrc = (id?: number, url?: string, size?: number, base64?: boolean) => {
    return this.getImgSrcImpl(base64 ?? !this.imagesAreFromSameServer, false, false, id, url, size);
  };

  static getImgSrcSet = (id?: number, url?: string, size?: number) => {
    return this.getImgSrcImpl(false, false, true, id, url, size);
  };

  static getVideoImgSrc = (id?: number, url?: string, base64?: boolean) => {
    return this.getImgSrcImpl(base64 ?? !this.imagesAreFromSameServer, true, false, id, url, undefined, true);
  };

  static getThumbSrc = (id: number, base64?: boolean) => {
    return this.getImageSrcImpl(id, base64 ?? !this.imagesAreFromSameServer, 'thumb');
  };

  static getNormalSrc = (id: number, base64?: boolean) => {
    return this.getImageSrcImpl(id, base64 ?? !this.imagesAreFromSameServer, 'normal');
  };

  static getOriginalSrc = (id: number, base64?: boolean) => {
    return this.getImageSrcImpl(id, base64 ?? !this.imagesAreFromSameServer, 'original');
  };

  static get imagesAreFromSameServer() {
    return this._imagesAreFromSameServer;
  }

  static initialize = (appUrl: string) => {
    this._imagesAreFromSameServer = appUrl.startsWith(GlobalHelper.getMainApiUrl().replace('/api', ''));
  };

  private static _imagesAreFromSameServer = true;

  private static getOneSrcSet = (baseUrl: string, webp?: boolean, size?: number, mult?: number) => {
    const multForSize = mult ?? window.devicePixelRatio;
    const sizeString = !!size ? `/${size * multForSize}/${size * multForSize}` : '';
    const multPart = !!size && !!mult ? ` ${mult}x` : '';
    return baseUrl + sizeString + (webp === undefined ? '' : webp ? '/true' : '/false') + multPart;
  };

  private static getImgSrcImpl = (
    base64: boolean,
    isForVideo: boolean,
    srcSet: boolean,
    id?: number,
    url?: string,
    size?: number,
    noWebp?: boolean
  ) => {
    if (!id && !url) {
      // console.log('this.missingImageUrl', this.missingImageUrl);
      return this.missingImageUrl;
    }

    const imageBasePath = GlobalHelper.getMainApiUrl();
    const webp = noWebp ? undefined : GlobalHelper.supportsWebp ?? false;
    const base64Part = base64 ? '/base64' : '';
    const basePartInitial = !!id
      ? `${imageBasePath}/album/photos/${id}/image${base64Part}`
      : !!url
      ? imageBasePath + (url.startsWith('/api') ? url.substring(4) : url) + base64Part
      : this.missingImageUrl;
    const basePart = isForVideo ? basePartInitial.replace('/image', '/thumb') : basePartInitial;
    if (!srcSet) {
      return this.getOneSrcSet(basePart, webp, size);
    } else {
      const sizes = [1, 1.5, 2, 2.5, 3];
      return sizes.map((x) => this.getOneSrcSet(basePart, webp, size, x)).join(', ');
    }
  };

  private static getImageSrcImpl = (id: number, base64: boolean, type: 'thumb' | 'normal' | 'original') => {
    const imageBasePath = GlobalHelper.getMainApiUrl() + `/album/photos/${id}/`;
    const imageType = type === 'thumb' ? 'thumb' : type === 'normal' ? 'image/normal' : 'image/original';
    const webp = GlobalHelper.supportsWebp ?? false;
    const url = imageBasePath + imageType + (base64 ? '/base64' : '') + (webp ? '/true' : '/false');
    return url;
  };
}

export default PhotoHelper;
