interface LocationMapData {
  id: number;
  lat?: number;
  lon?: number;
  northLat?: number;
  westLon?: number;
  southLat?: number;
  eastLon?: number;
}

export const getNewLocationMapData = (lat?: number, lon?: number): LocationMapData => {
  return {
    id: 0,
    lat: lat,
    lon: lon,
    northLat: undefined,
    westLon: undefined,
    southLat: undefined,
    eastLon: undefined,
  };
};

export default LocationMapData;
