import { getNewUser, User } from '@ashiteam/base-react-lib/dist';

import LocationBase, { getNewLocationBase } from './LocationBase';

interface LocationForList extends LocationBase {
  owner: User;
  canEdit: boolean;
  albumCount: number;
  eventCount: number;
  photoCount: number;
}

export const getNewLocationForList = (
  id?: number,
  name?: string,
  mapUrl?: string,
  aboutUrl?: string,
  lat?: number,
  lon?: number,
  canEdit: boolean = true
): LocationForList => {
  return {
    ...getNewLocationBase(id, name, aboutUrl, lat, lon),
    mapUrl: mapUrl,
    canEdit: canEdit,
    owner: getNewUser(),
    albumCount: 0,
    eventCount: 0,
    photoCount: 0,
  };
};

export default LocationForList;
