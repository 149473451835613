import axios from 'axios';
import { makeAutoObservable, runInAction } from 'mobx';
import { toast } from 'react-toastify';

import initAxios from '../api/agent';
import AdminAction from '../models/AdminAction';
import DuplicatePhotosData from '../models/DuplicatePhotosData';

export default class AdminActionStore {
  adminActions: AdminAction[] = [];
  isLoadingActions: boolean = false;
  isRunningAction: boolean = false;
  runningActionId: number = 0;
  isLoadingDuplicatePhotos: boolean = false;
  loadedDuplicatePhotos: boolean = false;
  duplicatePhotosData: DuplicatePhotosData[] = [];
  savingChanges: boolean = false;

  constructor() {
    makeAutoObservable(this);

    initAxios();
  }

  loadActions = async () => {
    if (this.isLoadingActions) {
      return;
    }

    this.isLoadingActions = true;
    // admin/actions
    const data = (await axios.get<AdminAction[]>('/album/admin/actions')).data;
    if (!!data) {
      // console.log(data);
      runInAction(() => (this.adminActions = data));
    }
    try {
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.isLoadingActions = false));
    }
  };

  doAction = async (id: number) => {
    if (this.isRunningAction) {
      return;
    }
    this.isRunningAction = true;
    this.runningActionId = id;
    try {
      const data = (await axios.get<AdminAction[]>('/album/admin/doaction/' + id)).data;
      console.log(data);
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => {
        this.isRunningAction = false;
        this.runningActionId = 0;
      });
    }
    // admin/doaction/{id}
  };

  get runningActionName() {
    if (this.isRunningAction && this.runningActionId > 0) {
      const action = this.adminActions.find((x) => x.id === this.runningActionId);
      if (!!action) {
        return action.buttonText;
      }
    }
    return undefined;
  }

  loadDuplicatePhotos = async (reload: boolean) => {
    if (!this.isLoadingDuplicatePhotos && (!this.loadedDuplicatePhotos || reload)) {
      this.isLoadingDuplicatePhotos = true;
      try {
        const duplicateData = (await axios.get<DuplicatePhotosData[]>('/album/admin/getduplicatephotos')).data;
        // console.log(duplicateData);
        if (!!duplicateData) {
          runInAction(() => {
            this.duplicatePhotosData = duplicateData;
          });
        }
      } catch (error: any) {
        console.log(error);
        toast.error('ERROR loading duplicates\r\n' + (error.message ?? JSON.stringify(error)));
      } finally {
        runInAction(() => {
          this.loadedDuplicatePhotos = true;
          this.isLoadingDuplicatePhotos = false;
        });
      }
    }
  };

  saveChanges = async () => {
    try {
      this.savingChanges = true;
      const changes = this.duplicatePhotosData.filter((x) => x.duplicatePhotos.some((y) => y.duplicateDeleted));
      if (changes.length > 0) {
        const ret = (await axios.post<boolean>('/album/admin/deleteduplicatephotos', changes)).data;
        if (ret) {
          const remaining = this.duplicatePhotosData.filter((x) => x.duplicatePhotos.some((y) => !y.duplicateDeleted));
          runInAction(() => {
            remaining.forEach((x) => (x.duplicatePhotos = x.duplicatePhotos.filter((y) => !y.duplicateDeleted)));
            this.duplicatePhotosData = remaining;
          });
        }
      }
    } catch (error: any) {
      console.log(error);
      toast.error('ERROR updating duplicates\r\n' + (error.message ?? JSON.stringify(error)));
    } finally {
      runInAction(() => {
        this.savingChanges = false;
      });
    }
  };

  private setDuplicateDeleteState = (albumId: number, photoOneId: number, photoTwoId: number, deleted: boolean) => {
    const duplicatePhotosData = this.duplicatePhotosData.find((x) => x.ablumId === albumId && x.duplicatePhotos);
    const duplicate = duplicatePhotosData?.duplicatePhotos.find(
      (y) => y.photoOneId === photoOneId && y.photoTwoId === photoTwoId
    );
    if (!!duplicate) {
      duplicate.duplicateDeleted = deleted;
    }
  };

  deleteLocalDuplicate = (albumId: number, photoOneId: number, photoTwoId: number) => {
    this.setDuplicateDeleteState(albumId, photoOneId, photoTwoId, true);
  };

  unDeleteLocalDuplicate = (albumId: number, photoOneId: number, photoTwoId: number) => {
    this.setDuplicateDeleteState(albumId, photoOneId, photoTwoId, false);
  };

  private setAllDuplicateDeleteStateForAlbum = (albumId: number, deleted: boolean) => {
    const duplicates = this.duplicatePhotosData.find((x) => x.ablumId === albumId)?.duplicatePhotos;
    duplicates?.forEach((x) => (x.duplicateDeleted = deleted));
  };

  deleteAllLocalDuplicateForAlbum = (albumId: number) => {
    this.setAllDuplicateDeleteStateForAlbum(albumId, true);
  };

  unDeleteAllLocalDuplicateForAlbum = (albumId: number) => {
    this.setAllDuplicateDeleteStateForAlbum(albumId, false);
  };

  haveAnyDeletedInAlbum = (albumId: number) => {
    return (
      this.duplicatePhotosData.find((x) => x.ablumId === albumId)?.duplicatePhotos.some((y) => !!y.duplicateDeleted) ??
      false
    );
  };

  isAllDeletedInAlbum = (albumId: number) => {
    return !(
      this.duplicatePhotosData.find((x) => x.ablumId === albumId)?.duplicatePhotos.some((y) => !y.duplicateDeleted) ??
      true
    );
  };

  private setAllDuplicateDeleteState = (deleted: boolean) => {
    const duplicates = this.duplicatePhotosData.map((x) => x.duplicatePhotos);
    duplicates.forEach((x) => x.forEach((y) => (y.duplicateDeleted = deleted)));
  };

  deleteAllLocalDuplicate = () => {
    this.setAllDuplicateDeleteState(true);
  };

  unDeleteAllLocalDuplicate = () => {
    this.setAllDuplicateDeleteState(false);
  };

  get haveAnyDeleted() {
    return this.duplicatePhotosData.some((x) => x.duplicatePhotos.some((y) => !!y.duplicateDeleted));
  }

  get allDeleted() {
    return !this.duplicatePhotosData.some((x) => x.duplicatePhotos.some((y) => !y.duplicateDeleted));
  }
}
