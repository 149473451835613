import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const ComponentLoading = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        width: '100%',
        minHeight: '100px',
        flexDirection: 'column',
        p: 2,
      }}
    >
      <Typography variant='h4' sx={{ textAlign: 'center', width: '100%' }}>
        Loading...
      </Typography>
    </Box>
  );
};

export default ComponentLoading;
