import initAxios from '../api/agent';
import AlbumUserDetailParams, {
    getNewAlbumUserDetailParams
} from '../models/params/AlbumUserDetailParams';
import BaseParams, { getNewBaseParams } from '../models/params/BaseParams';
import UserForDetail, {
    getNewUserForDetail, getUserForListFromUserForDetail
} from '../models/UserForDetail';
import UserForList from '../models/UserForList';
import UserForListBase from '../models/UserForListBase';
import BaseStore from './BaseStore';

export default class AlbumUserStore extends BaseStore<
  UserForListBase,
  BaseParams,
  UserForDetail,
  AlbumUserDetailParams,
  UserForDetail,
  UserForList
> {
  readonly itemsUrlPart = '/users/users';
  readonly itemUrlPart = '/users/view/';

  constructor() {
    super(
      getNewBaseParams,
      getNewAlbumUserDetailParams,
      getNewUserForDetail,
      getNewUserForDetail,
      getUserForListFromUserForDetail
    );
    this.params.pageSize = 50;
    initAxios();
  }
}
