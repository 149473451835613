import AlbumEventBase, { getNewAlbumEventBase } from './AlbumEventBase';

interface AlbumEventForAlbum extends AlbumEventBase {
  ownerId: number;
  catId: number;
}

export const getNewAlbumEventForAlbum = (name: string = '') => {
  const albumEvent: AlbumEventForAlbum = {
    ...getNewAlbumEventBase(name),
    ownerId: 0,
    catId: 0,
  };
  return albumEvent;
};

export default AlbumEventForAlbum;
