interface CategoryBase {
  id: number;
  name: string;
  canEdit: boolean;
}

export const getNewCategoryBase = (name: string = ''): CategoryBase => {
  return {
    id: 0,
    name: name,
    canEdit: true,
  };
};

export default CategoryBase;
