import axios from 'axios';
import { makeAutoObservable, runInAction } from 'mobx';

import initAxios from '../api/agent';
import PhotoHelper from '../lib/PhotoHelper';

interface ImageData {
  isBase64: boolean;
  url: string;
}

type ImageMap = Map<number, ImageData>;

export default class ImageStore {
  normalImages = new Map<number, ImageData>();
  thumbImages = new Map<number, ImageData>();
  startItemLoaded: boolean = false;
  startItemId: number = 0;

  constructor() {
    initAxios();

    makeAutoObservable(this);
  }

  clearAll = () => {
    for (const key in this.normalImages.keys()) {
      this.removeImageData(+key);
    }
    for (const key in this.thumbImages.keys()) {
      this.removeImageData(+key);
    }
    this.normalImages.clear();
    this.thumbImages.clear();
    this.clearStartItem();
  };

  clearStartItem = () => {
    this.startItemId = 0;
    this.startItemLoaded = false;
  };

  setStartItemId = (id: number) => {
    this.startItemId = id;
    this.startItemLoaded = this.isLoaded(id, false);
  };

  load = (
    ids: number | number[],
    normalIsBase64: boolean,
    thumbIsBase64: boolean,
    reload: boolean,
    priorityStartIndex?: number,
    priorityEndIndex?: number
  ): AbortController => {
    console.log('images load');
    const controller = new AbortController();
    if (!reload) {
      this.removeImageData(ids);
    }

    if (Array.isArray(ids)) {
      if (!!priorityEndIndex && !!priorityStartIndex && priorityEndIndex < priorityStartIndex) {
        priorityEndIndex = undefined;
      }
      if (!!priorityStartIndex && !priorityEndIndex && priorityStartIndex > 0 && priorityStartIndex < ids.length) {
        // only start given, so start from it (ignoring if start was 0 as then it is already in the right order)
        const partToPutBehind = ids.splice(0, priorityStartIndex - 1);
        ids.push(...partToPutBehind);
      }
      if (
        !!priorityStartIndex &&
        !!priorityEndIndex &&
        priorityStartIndex > 0 &&
        priorityStartIndex < ids.length &&
        priorityEndIndex > 0 &&
        priorityEndIndex < ids.length
      ) {
        const startPart = ids.splice(priorityStartIndex, priorityEndIndex - priorityStartIndex + 1);
        ids = startPart.concat(ids);
      }
    }

    this.loadImagesHelper(ids, normalIsBase64, thumbIsBase64, controller); // not waiting

    return controller;
  };

  isLoaded = (ids: number | number[], thumb: boolean): boolean => {
    if (ids === undefined) {
      return false;
    }
    if (typeof ids === 'number') {
      return (thumb ? this.thumbImages : this.normalImages).has(ids);
    } else {
      return ids.every((id) => this.isLoaded(id, thumb));
    }
  };

  removeImageData = (ids: number | number[]) => {
    if (typeof ids === 'number') {
      this.removeImageDataHelper(this.normalImages, ids);
      this.removeImageDataHelper(this.thumbImages, ids);
    } else {
      ids.forEach((id) => {
        this.removeImageData(id);
      });
    }
  };

  private get urlCreator() {
    return window.URL || window.webkitURL;
  }

  private loadImagesHelper = async (
    ids: number | number[],
    normalIsBase64: boolean,
    thumbIsBase64: boolean,
    controller: AbortController
  ) => {
    if (typeof ids === 'number') {
      if (!this.thumbImages.has(ids)) {
        const thumbUrl = await this.fetchImage(ids, thumbIsBase64, true, controller);
        if (!!thumbUrl) {
          runInAction(() => {
            this.thumbImages.set(ids, { isBase64: thumbIsBase64, url: thumbUrl });
          });
        }
      }

      if (!this.normalImages.has(ids)) {
        const normalUrl = await this.fetchImage(ids, normalIsBase64, false, controller);
        if (!!normalUrl) {
          runInAction(() => {
            this.normalImages.set(ids, { isBase64: normalIsBase64, url: normalUrl });
            if (ids === this.startItemId) {
              this.startItemLoaded = true;
              console.log('Start item LOADED');
            } else {
              if (this.startItemLoaded) {
                console.log('Loading more items after start item was loaded');
              }
            }
          });
        }
      }
    } else {
      const chunkSize = 12;
      const idCopy = ids.slice(0);
      while (idCopy.length > 0) {
        const chunk = idCopy.splice(0, Math.min(chunkSize, idCopy.length));
        const promisses: Promise<void>[] = [];
        for (let i = 0; i < chunk.length; ++i) {
          promisses.push(this.loadImagesHelper(chunk[i], normalIsBase64, thumbIsBase64, controller));
        }
        await Promise.all(promisses);
      }
      // for (let i = 0; i < ids.length; ++i) {
      //   await this.loadImagesHelper(ids[i], normalIsBase64, thumbIsBase64, controller);
      // }
    }
  };

  private removeImageDataHelper = (imageMap: ImageMap, id: number) => {
    if (imageMap.has(id)) {
      const imageData = imageMap.get(id)!;
      if (!imageData.isBase64) {
        // console.log('Revoking URL', imageData.url);
        URL.revokeObjectURL(imageData.url);
      }
      imageMap.delete(id);
    }
  };

  private fetchImage = async (id: number, base64: boolean, isThumb: boolean, controller: AbortController) => {
    try {
      if (!controller.signal.aborted) {
        const itemSrc = isThumb
          ? PhotoHelper.getImgSrc(id, undefined, PhotoHelper.defaultThumbSize, base64)
          : PhotoHelper.getNormalSrc(id, base64);
        if (base64) {
          const response = await axios.get<string>(itemSrc, { signal: controller.signal });
          return response.data;
        } else {
          const response = await axios.get(itemSrc, { responseType: 'blob', signal: controller.signal });
          return this.urlCreator.createObjectURL(response.data);
        }
      }
    } catch (error: any) {
      if (error.code !== 'ERR_CANCELED') {
        console.log(error);
      }
    }
    return undefined;
  };
}
