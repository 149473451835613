interface AlbumEventDetailParams {
  albums: boolean;
  photos: boolean;
}

export const getNewAlbumEventDetailParams = (
  albums: boolean = true,
  photos: boolean = false
): AlbumEventDetailParams => {
  return {
    albums: albums,
    photos: photos,
  };
};

export default AlbumEventDetailParams;
