import AlbumEventForList from './AlbumEventForList';
import AlbumForList from './AlbumForList';
import CategoryBase from './CategoryBase';
import CategoryForList, { getNewCategoryForList } from './CategoryForList';

interface Category extends CategoryForList {
  albums: AlbumForList[];
  events: AlbumEventForList[];
}

export const getNewCategory = (name: string = ''): Category => {
  return {
    ...getNewCategoryForList(name),
    albums: [],
    events: [],
  };
};

export const getCategoryBaseFromCategory = (item: Category, ownerId: number): CategoryBase => {
  const outItem: CategoryBase = {
    id: item.id,
    name: item.name,
    canEdit: true,
  };
  return outItem;
};

export default Category;
