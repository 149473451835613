import { ThemeOptions } from '@mui/material/styles';
import createTheme from '@mui/material/styles/createTheme';

export const lightThemeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: '#e95420',
    },
    secondary: {
      main: '#aea79f',
    },
    background: {
      default: '#fff',
      paper: '#fff',
    },
    error: {
      main: '#df382c',
    },
    warning: {
      main: '#efb73e',
    },
    info: {
      main: '#17a2b8',
    },
    success: {
      main: '#38b44a',
    },
  },
  typography: {
    fontFamily: [
      // '"Roboto Condensed", sans-serif',
      '"Kalam", cursive',
      '"Roboto", sans-serif',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          // fontSize: '1rem',
          // paddingTop: '6px',
        },
      },
    },
  },
};

export const darkThemeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: '#772953',
    },
    secondary: {
      main: '#aea79f',
    },
    background: {
      default: '#fff',
      paper: '#fff',
    },
    error: {
      main: '#df382c',
    },
    warning: {
      main: '#efb73e',
    },
    info: {
      main: '#17a2b8',
    },
    success: {
      main: '#38b44a',
    },
  },
  typography: {
    fontFamily: [
      // '"Roboto Condensed", sans-serif',
      '"Kalam", cursive',
      '"Roboto", sans-serif',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          // fontSize: '1rem',
          // paddingTop: '6px',
        },
      },
    },
  },
};

export const lightTheme = createTheme(lightThemeOptions);
export const darkTheme = createTheme(darkThemeOptions);
