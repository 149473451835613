import BaseParams, { getNewBaseParams } from './BaseParams';
import CategoryParams, { getNewCategoryParams } from './CategoryParams';

interface CategoryPagedParams extends BaseParams, CategoryParams {}

export const getNewCategoryPagedParams = (
  nameLike?: string,
  orderBy?: string,
  mine?: boolean,
  albums: boolean = true,
  events: boolean = true,
  pageNumber: number = 1,
  pageSize: number = 10
): CategoryPagedParams => {
  return {
    ...getNewBaseParams(nameLike, orderBy, mine, pageNumber, pageSize),
    ...getNewCategoryParams(albums, events),
  };
};

export default CategoryPagedParams;
