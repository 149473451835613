interface AlbumEventBase {
  id: number;
  name: string;
  description?: string;
  isPublic: boolean;
  dateCreated: string;
  dateLastUpdated: Date;
  fromDate?: Date;
  toDate?: Date;
  canEdit: boolean;
  albumCount?: number;
  autoDates?: boolean;
  autoLocations?: boolean;
}

export const getNewAlbumEventBase = (name: string = ''): AlbumEventBase => {
  return {
    id: 0,
    name: name,
    description: undefined,
    isPublic: false,
    dateCreated: new Date(Date.now()).toISOString(),
    dateLastUpdated: new Date(Date.now()),
    fromDate: undefined,
    toDate: undefined,
    canEdit: true,
    albumCount: 0,
    autoDates: true,
    autoLocations: true,
  };
};

export default AlbumEventBase;
