import axios from 'axios';

import { WindowSizeData } from '@ashiteam/base-react-lib/dist/hooks/WindowSize';
import getBodyWidth from '@ashiteam/base-react-lib/dist/lib/getBodyWidth';
import UnderConstruction from '@ashiteam/mui-react-lib/dist/components/UnderConstruction';
import { Breakpoint, Theme } from '@mui/material/styles';

import { EAdvanceDataEntry, UserPreferences } from '../models/User';
import PhotoHelper from './PhotoHelper';

export default class GlobalHelper {
  static readonly development: boolean = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
  static readonly port = 5010;
  static supportsWebp: boolean | undefined = undefined;
  static containerMaxWidthPx = 1200;
  static smWidthPx = 600;

  private static _mainApiUrl = 'https://album.weerapura.com/api';

  static initialize(theme: Theme, appUrl: string) {
    GlobalHelper.containerMaxWidthPx = GlobalHelper.getBreakpointWidth(theme, GlobalHelper.containerMaxWidth);
    GlobalHelper.smWidthPx = GlobalHelper.getBreakpointWidth(theme, 'sm');
    if (this.development && appUrl.startsWith('http://localhost')) {
      // ping local host server, if found then set _mainApiUrl = 'http://localhost:5000/api'
      const localApi = `http://localhost:${GlobalHelper.port}/api`;
      axios
        .get<string>(localApi + '/album/ping')
        .then((response) => {
          // console.log('response', response);
          if (response.data === 'OK') {
            console.log('Have a running local server, so going to use it');
            GlobalHelper._mainApiUrl = localApi;
            axios.defaults.baseURL = GlobalHelper.getMainApiUrl();
          }
        })
        .catch(() => {
          console.log('No running local server');
        })
        .finally(() => {
          PhotoHelper.initialize(appUrl);
        });
    } else {
      PhotoHelper.initialize(appUrl);
    }
    GlobalHelper.initWebpSupport();
  }

  static async initializeAsync(theme: Theme, appUrl: string) {
    GlobalHelper.containerMaxWidthPx = GlobalHelper.getBreakpointWidth(theme, GlobalHelper.containerMaxWidth);
    GlobalHelper.smWidthPx = GlobalHelper.getBreakpointWidth(theme, 'sm');
    if (this.development && appUrl.startsWith('http://localhost')) {
      // ping local host server, if found then set _mainApiUrl = 'http://localhost:5000/api'
      const localApi = `http://localhost:${GlobalHelper.port}/api`;
      try {
        const response = await axios.get<string>(localApi + '/album/ping');
        // console.log('response', response);
        if (response.data === 'OK') {
          console.log('Have a running local server, so going to use it');
          GlobalHelper._mainApiUrl = localApi;
          axios.defaults.baseURL = GlobalHelper.getMainApiUrl();
        }
      } catch (error) {
        console.log('No running local server');
      } finally {
        PhotoHelper.initialize(appUrl);
      }
    } else {
      PhotoHelper.initialize(appUrl);
    }
    GlobalHelper.initWebpSupport();
    console.log('initializeAsync Done');
  }

  static getBreakpointWidth(theme: Theme, breakpoint: Breakpoint) {
    // @media (min-width:1200px)
    const valStr = theme.breakpoints.up(breakpoint).replace('@media (min-width:', '').replace('px)', '');
    const val = +valStr;
    if (typeof val === 'number') {
      return val;
    }
    return GlobalHelper.getDefaultBreakpointWidth(breakpoint);
  }

  static getDefaultBreakpointWidth(breakpoint: Breakpoint) {
    switch (breakpoint) {
      case 'xs':
        return 0;

      case 'sm':
        return 600;

      case 'md':
        return 900;

      case 'lg':
        return 1200;

      case 'xl':
        return 1536;
    }
  }

  static get appName() {
    return 'Ashi Albums';
  }

  static get containerMaxWidth(): Breakpoint {
    return 'lg';
  }

  static get albumCardFetchImageSize() {
    return 300; // Math.floor(GlobalHelper.containerMaxWidthPx / 3);
  }

  static get oneSpaceInPx() {
    return 8;
  }

  static getMainPaddingInPx(isDrawerMode: boolean, padCount: number = 1) {
    return (isDrawerMode ? 8 : 12) * padCount;
  }

  static getPaddingInPx(padCount: number = 1) {
    return GlobalHelper.oneSpaceInPx * padCount;
  }

  static getAvailableBodyWidth(winSizeData: WindowSizeData) {
    return Math.min(
      winSizeData.bodyWidth === winSizeData.windowSize.width ? getBodyWidth() : winSizeData.bodyWidth ?? 1800,
      GlobalHelper.containerMaxWidthPx
    );
  }

  private static _scrollbarWidth = 0;
  static getScrollbarWidth = () => {
    if (this._scrollbarWidth === 0) {
      // Creating invisible container
      const outer = document.createElement('div');
      outer.style.visibility = 'hidden';
      outer.style.overflow = 'scroll'; // forcing scrollbar to appear
      //outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps
      document.body.appendChild(outer);

      // Creating inner element and placing it in the container
      const inner = document.createElement('div');
      outer.appendChild(inner);

      // Calculating difference between container's full width and the child width
      const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

      // Removing temporary elements from the DOM
      outer.parentNode?.removeChild(outer);

      this._scrollbarWidth = scrollbarWidth;

      // console.log('scrollbarWidth', this._scrollbarWidth);
    }
    return this._scrollbarWidth;
  };

  static getGridItemWidth(
    winSizeData: WindowSizeData,
    spacing: number,
    defaultItemWidth: number,
    padding: number,
    smColCount: number
  ) {
    const scrollWidth = this.getScrollbarWidth();
    const amtToAdjustForScroll = scrollWidth > 10 && (winSizeData.windowSize.width ?? 800) < 617 ? scrollWidth + 1 : 2;
    const availableBodyWidth = GlobalHelper.getAvailableBodyWidth(winSizeData);
    const isDrawerMode = getBodyWidth() < this.smWidthPx;
    // const haveScrollBar = !isDrawerMode; // && (winSizeData.windowSize.width ?? 0) > availableBodyWidth;
    const paddingWidth =
      GlobalHelper.getMainPaddingInPx(isDrawerMode, 4) +
      (padding > 0 ? GlobalHelper.getPaddingInPx(padding) : isDrawerMode ? 0 : GlobalHelper.oneSpaceInPx);
    const availableWidth = availableBodyWidth - paddingWidth - amtToAdjustForScroll;
    const colCountToUse = (isDrawerMode ? smColCount : undefined) ?? Math.floor(availableWidth / defaultItemWidth);
    const totalGap = (colCountToUse - 1) * spacing * GlobalHelper.oneSpaceInPx;
    const width = (availableWidth - totalGap) / colCountToUse;

    // console.log(
    //   isDrawerMode,
    //   // haveScrollBar,
    //   winSizeData.windowSize.width,
    //   availableBodyWidth,
    //   document.documentElement['clientWidth'],
    //   paddingWidth,
    //   availableWidth,
    //   colCountToUse,
    //   totalGap,
    //   width,
    //   winSizeData.bodyWidth === winSizeData.windowSize.width
    // );

    return width;
  }

  static getMainApiUrl = () => {
    return this._mainApiUrl;
    // return 'https://album.weerapura.com/api';
    // return 'http://localhost:5000/api';
  };

  static get logoImgSrc() {
    return '/images/logo/40/logo40.png';
  }

  static get logoImgSrcSet() {
    return `/images/logo/40/logo40.webp 1x,
            /images/logo/40/logo40.png 1x,
            /images/logo/40/logo40-1.5x.webp 1.5x,
            /images/logo/40/logo40-1.5x.png 1.5x,
            /images/logo/40/logo40-2x.webp 2x,
            /images/logo/40/logo40-2x.png 2x,
            /images/logo/40/logo40-2.4x.webp 2.4x,
            /images/logo/40/logo40-2.4x.png 2.4x,
            /images/logo/40/logo40-2.5x.webp 2.5x,
            /images/logo/40/logo40-2.5x.png 2.5x,
            /images/logo/40/logo40-3x.webp 3x,
            /images/logo/40/logo40-3x.png 3x`;
  }

  static get underConstructionImgSrc() {
    return '/images/underconstruction/100/underconstruction.png';
  }

  static get underConstructionImgSrcSet() {
    return `/images/underconstruction/100/underconstruction.webp 1x,
            /images/underconstruction/100/underconstruction.png 1x,
            /images/underconstruction/100/underconstruction-1.5x.webp 1.5x,
            /images/underconstruction/100/underconstruction-1.5x.png 1.5x,
            /images/underconstruction/100/underconstruction-2x.webp 2x,
            /images/underconstruction/100/underconstruction-2x.png 2x,
            /images/underconstruction/100/underconstruction-2.4x.webp 2.4x,
            /images/underconstruction/100/underconstruction-2.4x.png 2.4x,
            /images/underconstruction/100/underconstruction-2.5x.webp 2.5x,
            /images/underconstruction/100/underconstruction-2.5x.png 2.5x,
            /images/underconstruction/100/underconstruction-3x.webp 3x,
            /images/underconstruction/100/underconstruction-3x.png 3x`;
  }

  static get underConstructionComponent() {
    return <UnderConstruction imageSrc={this.underConstructionImgSrc} imageSrcSet={this.underConstructionImgSrcSet} />;
  }

  private static readonly preferencesKey = 'preferences';

  static get cachedPreferences() {
    const prefString = window.localStorage.getItem(GlobalHelper.preferencesKey);
    const prefs: UserPreferences =
      prefString !== undefined && prefString !== null
        ? JSON.parse(prefString)
        : { id: 0, viewAsTable: false, hideEdits: true, advanceDataEntry: EAdvanceDataEntry.ShowWhenAsked };
    return prefs;
  }

  static set cachedPreferences(value: UserPreferences) {
    // console.log('set cachedPreferences', value);
    if (!!value) {
      window.localStorage.setItem(GlobalHelper.preferencesKey, JSON.stringify(value));
    } else {
      window.localStorage.removeItem(GlobalHelper.preferencesKey);
    }
  }

  static initWebpSupport = async () => {
    if (this.supportsWebp === undefined) {
      this.supportsWebp = await new Promise<boolean>((res) => {
        const webP = new Image();
        webP.src =
          'data:image/webp;base64,UklGRjoAAABXRUJQVlA4IC4AAACyAgCdASoCAAIALmk0mk0iIiIiIgBoSygABc6WWgAA/veff/0PP8bA//LwYAAA';
        webP.onload = webP.onerror = () => {
          res(webP.height === 2);
        };
      });
    }
  };

  static getCountTitle = (count: number, name: string) => {
    return `${count} ${name}${count !== 1 ? 's' : ''}`;
  };
}
