import axios from 'axios';
import { makeAutoObservable, reaction, runInAction } from 'mobx';
import { NavigateFunction } from 'react-router-dom';

import User from '@ashiteam/base-react-lib/dist/models/User/User';
import UserForLogin from '@ashiteam/base-react-lib/dist/models/User/UserForLogin';

import initAxios from '../api/agent';
import GlobalHelper from '../lib/GlobalHelper';
import { EThumbnailSize, UserPreferences, UserSigninRetData } from '../models/User';
import { store } from './Store';

const me = 'asanga@weerapura.com';

export default class UserStore {
  user?: User = undefined;
  preferences: UserPreferences = GlobalHelper.cachedPreferences;
  token: string | null = window.localStorage.getItem('jwt');

  constructor() {
    makeAutoObservable(this);

    initAxios();

    reaction(
      () => this.token,
      (token: string | null) => {
        if (token) {
          window.localStorage.setItem('jwt', token);
        } else {
          window.localStorage.removeItem('jwt');
        }
      }
    );

    reaction(
      () => this.preferences,
      (preferences: UserPreferences) => {
        // console.log('Setting GlobalHelper.cachedPreferences', preferences);
        this.persistPreferences();
      }
    );
  }

  signIn = async (userForLogin: UserForLogin, navigate?: NavigateFunction): Promise<boolean> => {
    try {
      const userData = await this.login(userForLogin);
      if (!userData || !userData.user) {
        console.log('user was null');
        return false;
      }
      // console.log(userData);
      runInAction(() => {
        this.user = userData.user;
        if (!!userData.preferences) {
          this.preferences = userData.preferences;
        }
      });
      console.log('signIn', userData.user);
      this.setToken(userData.token ?? null);
      if (navigate) {
        navigate('/');
        // window.location.reload();
      }
      return true;
    } catch (error: any) {
      // throw error;
      console.log('login error');
      console.log(error);
      return false;
    }
  };

  signOut = async () => {
    await this.logout();
    this.setToken(null);
  };

  setToken = (token: string | null) => {
    const isNewToken = this.token !== token;
    this.token = token;
    if (!this.token) {
      this.user = undefined;
    }
    if (isNewToken) {
      store.navStore.loadActiveMenu(true);
      store.albumStore.clear();
      store.categoryStore.clear();
      store.eventStore.clear();
      store.locationStore.clear();
      // if (!this.isLoggedIn()) {
      //   window.location.reload();
      // }
    }
  };

  isLoggedIn = () => {
    return !!this.user && !!this.token;
  };

  isAdmin = () => {
    return this.isLoggedIn() && (this.user?.email === me || this.user?.userName === me);
  };

  loadUser = async () => {
    try {
      // const userData = await agent.Account.current();
      const userData = await this.getCurrentUser();
      if (!userData || !userData.user) {
        throw new Error('user was null');
      }
      if (!!userData.user) {
        runInAction(() => {
          this.user = userData.user;
          if (!!userData.preferences) {
            this.preferences = userData.preferences;
          }
        });
        store.navStore.loadActiveMenu(true);
      }
    } catch (error: any) {
      console.log(error);
      this.setToken(null);
    }
  };

  get viewAsTable() {
    return this.preferences?.viewAsTable ?? false;
  }

  setViewAsTable = (value: boolean) => {
    if (this.preferences) {
      this.preferences.viewAsTable = value;
      this.persistPreferences();
    }
  };

  get hideEdits() {
    return this.preferences?.hideEdits ?? false;
  }

  setHideEdits = (value: boolean) => {
    if (this.preferences) {
      this.preferences.hideEdits = value;
      this.persistPreferences();
    }
  };

  get thumbNailSize() {
    return this.preferences?.thumbnailSize ?? EThumbnailSize.defaultSize;
  }

  setThumbNailSize = (value: EThumbnailSize) => {
    if (this.preferences) {
      this.preferences.thumbnailSize = value;
      this.persistPreferences();
    }
  };

  private persistPreferences = () => {
    GlobalHelper.cachedPreferences = this.preferences;
    if (!!this.user) {
      this.savePreferences(this.user.id, this.preferences);
    }
  };

  private getCurrentUser = async () => {
    const response = await axios.get<UserSigninRetData>('/auth/me');
    return response.data;
  };

  private login = async (user: UserForLogin) => {
    console.log('login', GlobalHelper.getMainApiUrl());
    return (await axios.post<UserSigninRetData>('/auth/signin', user)).data;
    // return response.data;
  };

  private logout = async () => {
    return (await axios.post<string>('/auth/signout', {})).data;
  };

  private savePreferences = async (id: number, preferences: UserPreferences) => {
    return (await axios.post('/auth/preferences/' + id, preferences)).data;
  };
}

// export const getOwnerUser = () => {
//   return store?.userStore?.user ?? getNewUser();
// };

// export const getOwnerUserId = () => {
//   return getOwnerUser().id;
// };
