import initAxios from '../api/agent';
import Category, { getCategoryBaseFromCategory, getNewCategory } from '../models/Category';
import CategoryBase from '../models/CategoryBase';
import CategoryForList from '../models/CategoryForList';
import CategoryPagedParams, {
    getNewCategoryPagedParams
} from '../models/params/CategoryPagedParams';
import CategoryParams, { getNewCategoryParams } from '../models/params/CategoryParams';
import BaseStore from './BaseStore';

export default class CategoryStore extends BaseStore<
  CategoryForList,
  CategoryPagedParams,
  Category,
  CategoryParams,
  Category,
  CategoryBase
> {
  readonly itemsUrlPart = '/album/pagedcategories';
  readonly itemUrlPart = '/album/category/';

  constructor() {
    super(getNewCategoryPagedParams, getNewCategoryParams, getNewCategory, getNewCategory, getCategoryBaseFromCategory);
    initAxios();
  }
}
