import AlbumEventForList from './AlbumEventForList';
import AlbumForList from './AlbumForList';
import UserForList from './UserForList';

interface UserForDetail extends UserForList {
  ownedAlbumEvents: AlbumEventForList[];
  ownedAlbums: AlbumForList[];
}

export const getNewUserForDetail = (): UserForDetail => {
  return {
    id: 0,
    created: new Date(Date.now()),
    isAdmin: false,
    knownAs: '',
    lastActive: new Date(Date.now()),
    userName: '',
    ownedAlbumEvents: [],
    ownedAlbums: [],
  };
};

export const getUserForListFromUserForDetail = (userDetail: UserForDetail): UserForList => {
  return {
    id: userDetail.id,
    created: userDetail.created,
    isAdmin: userDetail.isAdmin,
    knownAs: userDetail.knownAs,
    lastActive: userDetail.lastActive,
    userName: userDetail.userName,
  };
};

export default UserForDetail;
