import BaseParams, { getNewBaseParams } from './BaseParams';
import LocationParams, { getNewLocationParams } from './LocationParams';

interface LocationPagedParams extends BaseParams, LocationParams {}

export const getNewLocationPagedParams = (
  nameLike?: string,
  orderBy?: string,
  mine?: boolean,
  albums: boolean = true,
  events: boolean = true,
  pageNumber: number = 1,
  pageSize: number = 10
): LocationPagedParams => {
  return {
    ...getNewBaseParams(nameLike, orderBy, mine, pageNumber, pageSize),
    ...getNewLocationParams(albums, events),
  };
};

export default LocationPagedParams;
