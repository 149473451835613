import AlbumEventLocationForList from './AlbumEventLocationForList';
import AlbumLocationForLocationList from './AlbumLocationForLocationList';
import LocationBase from './LocationBase';
import LocationForList, { getNewLocationForList } from './LocationForList';
import Photo from './Photo';

interface Location extends LocationForList {
  eventLocations: AlbumEventLocationForList[];
  albumLocations: AlbumLocationForLocationList[];
  photos: Photo[];
}

export const getNewLocation = (): Location => {
  return {
    ...getNewLocationForList(),
    eventLocations: [],
    albumLocations: [],
    photos: [],
  };
};

export const getLocationBaseFromLocation = (item: Location, ownerId: number): any => {
  const outItem: LocationBase = {
    id: item.id,
    name: item.name,
    dateCreated: item.dateCreated,
    dateLastUpdated: new Date(Date.now()),
    mapImageUrl: item.mapImageUrl,
    aboutThumbUrl: item.aboutThumbUrl,
    aboutUrl: item.aboutUrl,
    mapData: item.mapData,
    mapUrl: item.mapUrl,
    nameData: item.nameData,
  };
  return outItem;
};

export default Location;
