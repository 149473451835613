import 'react-toastify/dist/ReactToastify.css';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import ThemeProvider from '@mui/material/styles/ThemeProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

// import cyan from '@mui/material/colors/cyan';
// import green from '@mui/material/colors/green';
// import grey from '@mui/material/colors/grey';
// import indigo from '@mui/material/colors/indigo';
// import orange from '@mui/material/colors/orange';
// import purple from '@mui/material/colors/purple';
// import red from '@mui/material/colors/red';
// import yellow from '@mui/material/colors/yellow';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { lightTheme } from './themes';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={lightTheme}>
      <BrowserRouter>
        <ToastContainer position='bottom-right' closeOnClick pauseOnFocusLoss />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <App />
        </LocalizationProvider>
      </BrowserRouter>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
