import axios from 'axios';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { toast } from 'react-toastify';

import initAxios from '../api/agent';
import Album, { getAlbumEditFromAlbum, getNewAlbum } from '../models/Album';
import AlbumForEdit from '../models/AlbumForEdit';
import AlbumForList from '../models/AlbumForList';
import AlbumDetailParams, { getAlbumDetailParams } from '../models/params/AlbumDetailParams';
import AlbumParams, { getNewAlbumParams } from '../models/params/AlbumParams';
import Photo from '../models/Photo';
import BaseStore from './BaseStore';

export default class AlbumStore extends BaseStore<
  AlbumForList,
  AlbumParams,
  Album,
  AlbumDetailParams,
  Album,
  AlbumForEdit
> {
  readonly itemsUrlPart = '/album/albums';
  readonly itemUrlPart = '/album/album/';

  editItemBackup: Album;

  constructor() {
    super(getNewAlbumParams, getAlbumDetailParams, getNewAlbum, getNewAlbum, getAlbumEditFromAlbum);
    this.params.pageSize = 10;
    initAxios();

    this.editItemBackup = getNewAlbum();

    makeObservable(this, {
      editItemBackup: observable,

      havePhotoChanges: computed,
      haveNonPhotoChanges: computed,
      haveAnyChanges: computed,
      haveAnySelected: computed,
      haveAnyUnselected: computed,

      undoWorkingPhotoChanges: action,
      saveWorkingPhotos: action,
      reorderPhotos: action,
      moveToEnd: action,
      swapPhotos: action,
      fixMissingMainPhoto: action,
      makePhotoMain: action,
      deletePhoto: action,
      addPhoto: action,
      selectPhoto: action,
      selectAllPhotos: action,
      deleteSelected: action,
    });
  }

  private getPhotoCopy = (photos: Photo[], unselectAll: boolean) => {
    const photosCopy = JSON.parse(JSON.stringify(photos));
    if (unselectAll) {
      photosCopy.forEach((x) => (x.isSelected = false));
    }
    return photosCopy;
  };

  private getAlbumCopyString = (album: Album, unselectAll: boolean) => {
    const albumCopy: Album = JSON.parse(JSON.stringify(album));
    if (unselectAll) {
      albumCopy.photos.forEach((x) => (x.isSelected = false));
    }
    return JSON.stringify(albumCopy);
  };

  private getAlbumCopyNoPhotos = (album: Album) => {
    const albumCopy: Album = JSON.parse(JSON.stringify(album));
    albumCopy.photos = [];
    return albumCopy;
  };

  private logDifference = (caller: string, first: string, second: string) => {
    if (first !== second) {
      console.log(caller, 'lengths', first.length, second.length);
      for (let i = 0; i < Math.min(first.length, second.length); ++i) {
        if (first[i] !== second[i]) {
          console.log('Different at ' + i);
          const start = Math.max(i - 20, 0);
          console.log('first', first.substring(start, start + 50));
          console.log('second', second.substring(start, start + 50));
          break;
        }
      }
    }
  };

  get havePhotoChanges() {
    const photos: Photo[] = this.getPhotoCopy(this.editItemBackup.photos, true);
    this.logDifference('havePhotoChanges', JSON.stringify(this.editItem.photos), JSON.stringify(photos));
    return JSON.stringify(this.editItem.photos) !== JSON.stringify(photos);
  }

  get haveAnyChanges() {
    return this.getAlbumCopyString(this.editItem, true) !== this.getAlbumCopyString(this.editItemBackup, true);
  }

  get haveNonPhotoChanges() {
    return (
      JSON.stringify(this.getAlbumCopyNoPhotos(this.editItem)) !==
      JSON.stringify(this.getAlbumCopyNoPhotos(this.editItemBackup))
    );
  }

  get haveAnySelected() {
    return this.editItemBackup.photos.some((x) => x.isSelected);
  }

  get haveAnyUnselected() {
    return this.editItemBackup.photos.some((x) => !x.isSelected);
  }

  loadItemForEditEx = async (id: number) => {
    const loaded = await this.loadItemForEdit(id);
    if (loaded) {
      runInAction(() => {
        this.editItem.photos.forEach((x) => (x.isSelected = false));
        this.editItem.photos.sort((a, b) => a.pos - b.pos);
        this.editItemBackup = JSON.parse(JSON.stringify(this.editItem));
      });
    }
    return loaded;
  };

  undoWorkingPhotoChanges = () => {
    this.editItemBackup.photos = JSON.parse(JSON.stringify(this.editItem.photos));
  };

  saveWorkingPhotos = async () => {
    try {
      const autoSort = (this.sortIsDifferent ? false : undefined) ?? this.editItem.autoSortPhotos;
      const url = this.itemUrlPart + `photos/${this.editItemBackup.id}${autoSort !== undefined ? `/${autoSort}` : ''}`;
      // console.log(this.editItemBackup.photos);
      await axios.put(url, this.editItemBackup.photos);

      runInAction(() => {
        this.editItem.photos = JSON.parse(JSON.stringify(this.editItemBackup.photos));
        this.updateEditItemToList();
      });
    } catch (error: any) {
      console.log('ERROR loading item for edit', error);
      toast.error('ERROR loading item for edit\r\n' + (error.message ?? JSON.stringify(error)));
    }
  };

  swapPhotos = (id1: number, id2: number) => {
    const photos = [...this.editItemBackup.photos];
    const dragPhoto = photos.find((x) => x.id === id1);
    const dropPhoto = photos.find((x) => x.id === id2);

    if (!!dragPhoto && !!dropPhoto) {
      const tempPos = dragPhoto.pos;
      dragPhoto.pos = dropPhoto.pos;
      dropPhoto.pos = tempPos;
    }
    photos.sort((a, b) => a.pos - b.pos);

    this.editItemBackup.photos = photos;
  };

  reorderPhotos = (dragId: number, dropId: number) => {
    const photos = [...this.editItemBackup.photos];
    const dragPhoto = photos.find((x) => x.id === dragId);
    const dropPhoto = photos.find((x) => x.id === dropId);

    if (!!dragPhoto && !!dropPhoto) {
      const dragPos = dragPhoto.pos;
      const dropPos = dropPhoto.pos;
      if (dragPos < dropPos) {
        photos.forEach((x) => {
          if (x.pos > dragPos && x.pos <= dropPos) {
            x.pos--;
          }
        });
      } else if (dragPos > dropPos) {
        photos.forEach((x) => {
          if (x.pos < dragPos && x.pos >= dropPos) {
            x.pos++;
          }
        });
      }
      dragPhoto.pos = dropPos;
    }

    photos.sort((a, b) => a.pos - b.pos);

    this.editItemBackup.photos = photos;
  };

  moveToEnd = (id: number) => {
    const lastPos = Math.max(...this.editItemBackup.photos.map((x) => x.pos));
    const last = this.editItemBackup.photos.find((x) => x.pos === lastPos);
    if (!!last) {
      this.reorderPhotos(id, last.id);
    }
  };

  fixMissingMainPhoto = () => {
    if (this.editItem.photos.length > 0 && !this.editItem.photos.some((x) => x.isMain)) {
      this.editItem.photos[0].isMain = true;
    }
    if (this.editItemBackup.photos.length > 0 && !this.editItemBackup.photos.some((x) => x.isMain)) {
      this.editItemBackup.photos[0].isMain = true;
    }
  };

  makePhotoMain = (id: number) => {
    // console.log('makePhotoMain');
    const photos = [...this.editItemBackup.photos];
    // console.log(this.editItemBackup.photos);
    const currentMainPhoto = photos.find((x) => x.isMain);
    if (!!currentMainPhoto) {
      currentMainPhoto.isMain = false;
    }
    const newMainPhoto = photos.find((x) => x.id === id);
    if (!!newMainPhoto) {
      newMainPhoto.isMain = true;
    }
    photos.sort((a, b) => a.pos - b.pos);
    // console.log(photos);
    this.editItemBackup.photos = photos;
  };

  deletePhoto = (id: number) => {
    const index = this.editItemBackup.photos.findIndex((x) => x.id === id);
    this.editItemBackup.photos.splice(index, 1);
  };

  addPhoto = (photo: Photo) => {
    console.log('addPhoto', photo.id, this.haveAnyChanges);
    if (photo.id > 0 && !this.haveAnyChanges && !this.editItem.photos.some((x) => x.id === photo.id)) {
      // const photos = [...this.editItem.photos, photo];
      // photos.sort((a, b) => a.pos - b.pos);
      // this.editItem.photos = photos;
      console.log('adding Photo', photo.id);
      photo.isSelected = false;
      this.editItem.photos.push(photo);
      this.editItem.photos.sort((a, b) => a.pos - b.pos);
      this.editItemBackup = JSON.parse(JSON.stringify(this.editItem));
      this.updateEditItemToList();
      console.log('added Photo', photo.id, this.haveAnyChanges);
      console.log('have photo changes', this.havePhotoChanges);
      if (this.haveAnyChanges) {
        console.log('added Photo', photo.id, this.editItem.photos.length, this.editItemBackup.photos.length);
      }
    }
  };

  selectPhoto = (id: number, select: boolean) => {
    const photo = this.editItemBackup.photos.find((x) => x.id === id);
    if (!!photo) {
      photo.isSelected = select;
    }
  };

  selectAllPhotos = (select: boolean) => {
    this.editItemBackup.photos.forEach((x) => (x.isSelected = select));
  };

  deleteSelected = () => {
    const remainingPhotos = this.editItemBackup.photos.filter((x) => !x.isSelected);
    this.editItemBackup.photos = remainingPhotos;
  };

  private get sortIsDifferent() {
    const total = Math.min(this.editItem.photos.length, this.editItemBackup.photos.length);
    for (let i = 0; i < total; ++i) {
      if (this.editItem.photos[i].pos !== this.editItemBackup.photos[i].pos) {
        return false;
      }
    }
    return true;
  }

  private updateEditItemToList = () => {
    const index = this.items.findIndex((x) => x.id === this.editItem.id);
    if (index >= 0) {
      const item = this.items[index];
      item.cat = this.editItem.cat;
      item.catId = this.editItem.catId;
      item.description = this.editItem.description;
      item.event = this.editItem.event;
      item.eventId = this.editItem.eventId;
      item.fromDate = this.editItem.fromDate;
      item.isEncrypted = this.editItem.isEncrypted;
      item.isPublic = this.editItem.isPublic;
      item.locations = JSON.parse(JSON.stringify(this.editItem.locations));
      item.name = this.editItem.name;
      item.toDate = this.editItem.toDate;
      item.photoCount = this.editItem.photos.filter((x) => !x.isVideo).length;
      item.videoCount = this.editItem.photos.filter((x) => x.isVideo).length;

      const mainPhoto = this.editItem.photos.find((x) => x.isMain);
      if (!!mainPhoto) {
        item.mainPhotoUrl = `/api/album/photos/${mainPhoto.id}/image`;
      }
    }
    if (this.activeItem.id === this.editItem.id) {
      this.clearActiveItem();
    }
  };
}
