import AlbumAccess from './AlbumAccess';
import AlbumBase from './AlbumBase';
import AlbumBaseEx, { getNewAlbumBaseEx } from './AlbumBaseEx';
import AlbumLocationForAlbumList from './AlbumLocationForAlbumList';

interface AlbumForEdit extends AlbumBaseEx {
  autoDates?: boolean;
  autoLocations?: boolean;
  autoEvent?: boolean;
  autoCat?: boolean;
  autoSortPhotos?: boolean;
  locations: AlbumLocationForAlbumList[];
  accessUsers: AlbumAccess[];
}

export const getNewAlbumForEdit = (): AlbumForEdit => {
  return {
    ...getNewAlbumBaseEx(),
    autoDates: true,
    autoLocations: true,
    autoEvent: true,
    autoCat: true,
    autoSortPhotos: true,
    locations: [],
    accessUsers: [],
  };
};

export const getAlbumBaseFromAlbumForEdit = (item: AlbumForEdit, ownerId: number): AlbumBase => {
  const albumBase: AlbumBase = {
    id: item.id,
    catId: item.catId,
    dateCreated: item.dateCreated,
    dateLastUpdated: new Date(Date.now()),
    isEncrypted: item.isEncrypted,
    isPublic: item.isPublic,
    ownerId: item.ownerId > 0 ? item.ownerId : ownerId,
    description: item.description,
    eventId: item.eventId,
    fromDate: item.fromDate,
    name: item.name,
    toDate: item.toDate,
  };
  return albumBase;
};

export const getAlbumEditFromAlbumForEdit = (item: AlbumForEdit): AlbumForEdit => {
  return item;
};

export default AlbumForEdit;
