import axios from 'axios';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { toast } from 'react-toastify';

import PaginatedResult from '@ashiteam/base-react-lib/dist/models/Paging/PaginatedResult';
import Pagination, { getNewPagination } from '@ashiteam/base-react-lib/dist/models/Paging/Pagination';

import initAxios from '../api/agent';
import IGenBaseEditItemStore from '../interfaces/IGenBaseEditItemStore';
import IGenBaseItemsStore from '../interfaces/IGenBaseItemsStore';
import IGenBaseItemStore from '../interfaces/IGenBaseItemStore';
import IItem from '../models/IItem';
import BaseParams from '../models/params/BaseParams';
import { store } from './Store';

abstract class BaseStore<
  T extends IItem,
  P extends BaseParams,
  I extends IItem,
  PI,
  EI extends IItem,
  EIP extends IItem
> implements IGenBaseItemsStore<T, P>, IGenBaseItemStore<I, PI>, IGenBaseEditItemStore<EI>
{
  abstract readonly itemUrlPart: string;
  abstract readonly itemsUrlPart: string;

  loadingItems: boolean = false;
  pagination: Pagination = getNewPagination();
  params: P;
  searchText: string = '';
  items: T[] = [];

  loadingItem: boolean = false;
  itemParams: PI;
  activeItem: I;

  loadingEditItem: boolean = false;
  editItem: EI;

  private getNewItemImpl: () => I;
  private getNewEditItem: () => EI;
  private getNewParams: () => P;
  private getNewItemParams: () => PI;
  private prepareEditItemForPost: (item: EI, ownerId: number) => EIP;

  constructor(
    getNewParams: () => P,
    getNewItemParams: () => PI,
    getNewItem: () => I,
    getNewEditItem: () => EI,
    prepareEditItemForPost: (item: EI, ownerId: number) => EIP
  ) {
    initAxios();

    makeObservable(this, {
      loadingItems: observable,
      pagination: observable,
      params: observable,
      searchText: observable,
      items: observable,
      theSearchText: computed,
      isLoading: computed,
      hasMore: computed,
      isLoadingItem: computed,

      loadingItem: observable,
      itemParams: observable,
      activeItem: observable,
      editItem: observable,

      clear: action,
      clearEditItem: action,
      clearActiveItem: action,
      clearPaginationData: action,
      clearItemsData: action,
      // getNewItem: action,
      loadItems: action,
      loadNextPage: action,
      setSearchText: action,
      search: action,
      loadItem: action,
      newEditItem: action,
      setEditItem: action,
      loadItemForEdit: action,
      saveEditItem: action,
      deleteItem: action,
    });

    this.params = getNewParams();
    this.itemParams = getNewItemParams();
    this.activeItem = getNewItem();
    this.editItem = getNewEditItem();

    this.getNewItemImpl = getNewItem;
    this.getNewEditItem = getNewEditItem;
    this.getNewParams = getNewParams;
    this.getNewItemParams = getNewItemParams;
    this.prepareEditItemForPost = prepareEditItemForPost;
  }

  private get editItemGetUrlPart() {
    return this.itemUrlPart + 'foredit/';
  }

  private getEditItemUrl = (id: number): string => {
    return this.editItemGetUrlPart + id;
  };

  private getAddItemUrl = (): string => {
    return this.itemUrlPart.substring(0, this.itemUrlPart.length - 1);
  };

  private getUpdateItemUrl = (id: number): string => {
    return this.itemUrlPart + id;
  };

  private getDeleteItemUrl = (id: number): string => {
    return this.itemUrlPart + id;
  };

  private getNewItem = (): I => {
    return this.getNewItemImpl();
  };

  clear = () => {
    this.loadingItems = false;
    this.pagination = getNewPagination();
    this.pagination.currentPage = 0;
    this.pagination.totalPages = -1;
    this.params = this.getNewParams();
    this.params.pageNumber = 1;
    this.searchText = '';
    this.items = [];
    this.loadingItem = false;
    this.itemParams = this.getNewItemParams();
    this.activeItem = this.getNewItemImpl();
  };

  clearEditItem = () => {
    this.editItem = this.getNewEditItem();
  };

  clearActiveItem = () => {
    this.activeItem = this.getNewItemImpl();
  };

  clearPaginationData = () => {
    this.pagination = getNewPagination();
    this.pagination.currentPage = 0;
    this.pagination.totalPages = -1;
    this.params = this.getNewParams();
    this.params.pageNumber = 1;
  };

  clearItemsData = () => {
    this.clearPaginationData();
    this.searchText = '';
    this.items = [];
  };

  loadItems = async (reload: boolean): Promise<boolean> => {
    if (!this.loadingItems) {
      const mustLoad =
        reload || this.pagination?.totalPages === 0 || this.params.pageNumber > this.pagination?.currentPage;
      if (mustLoad) {
        runInAction(() => {
          this.loadingItems = true;
        });
        try {
          const params = this.params;
          // console.log(params.pageNumber);
          // this.eventParams.pageNumber = this.pagination.currentPage;
          const response = await axios.get<PaginatedResult<T[]>>(this.itemsUrlPart, { params });
          // console.log('response', response);
          const pagedData = response.data;
          // console.log('pagedData', pagedData);
          const data = pagedData.data;
          // console.log('data', data);
          const items = Array.isArray(data)
            ? reload || !this.items || !Array.isArray(this.items)
              ? data
              : [...this.items, ...data]
            : [];
          // console.log('items', items);
          runInAction(() => {
            this.items = items;
            this.pagination = pagedData.pagination;
            // console.log('pagination', this.pagination.currentPage, this.pagination.totalPages);
          });
          return true;
        } catch (error: any) {
          console.log('ERROR loading items', error);
          toast.error('ERROR loading items');
          toast.error(error.message ?? JSON.stringify(error));
        } finally {
          runInAction(() => {
            this.loadingItems = false;
          });
          // console.log('loadItems finally');
        }
      }
    }
    return false;
  };

  loadAllItems = async () => {
    let i = 0;
    this.clearItemsData();
    if (await this.loadItems(true)) {
      while (this.hasMore && ++i < 100) {
        if (!(await this.loadNextPage())) {
          return false;
        }
      }
      return true;
    }
    return false;
  };

  get isLoading() {
    return this.loadingItems;
  }

  get hasMore() {
    const has = this.pagination.currentPage < this.pagination.totalPages || this.pagination.totalPages < 0;
    // console.log('hasMore', has, this.pagination.currentPage, this.pagination.totalPages);
    return has;
  }

  loadNextPage = async () => {
    // console.log('loadNextPage');
    if (!this.loadingItems && this.hasMore) {
      this.params.pageNumber = this.pagination.currentPage + 1;
      return await this.loadItems(false);
    }
    return false;
  };

  get theSearchText() {
    return this.searchText;
  }

  setSearchText = (text: string) => {
    if (this.searchText !== text) {
      this.searchText = text;
      setTimeout(() => {
        if (this.searchText === text) {
          // still same so do the search
          this.search();
        }
      }, 3000);
    }
  };

  search = async () => {
    if (this.searchText !== this.params.nameLike) {
      this.params.nameLike = this.searchText;
      this.params.pageNumber = 1;
      this.pagination = getNewPagination();
      await this.loadItems(true);
    }
  };

  loadItem = async (id: number) => {
    // console.log('loadAlbum', this.loadingItem, this.activeItem.id, id);
    if (!this.loadingItem && this.activeItem.id !== id) {
      runInAction(() => (this.loadingItem = true));
      try {
        runInAction(() => {
          this.activeItem = this.getNewItem();
        });
        const params = this.itemParams;
        const item = (await axios.get<I>(this.itemUrlPart + id, { params })).data;
        // console.log('item', item);
        runInAction(() => {
          this.activeItem = item;
        });
      } catch (error: any) {
        console.log('ERROR loading item', error);
        toast.error('ERROR loading item');
        toast.error(error.message ?? JSON.stringify(error));
      } finally {
        runInAction(() => (this.loadingItem = false));
        // console.log('activeItem', this.activeItem);
      }
    }
  };

  get isLoadingItem() {
    return this.loadingItem;
  }

  newEditItem = () => {
    this.editItem = this.getNewEditItem();
  };

  setEditItem = (editItem: EI) => {
    this.editItem = editItem;
  };

  loadItemForEdit = async (id: number) => {
    // console.log('loadAlbum', this.loadingAlbum, this.activeAlbum.id, id);
    if (!this.loadingItem && this.editItem.id !== id) {
      runInAction(() => (this.loadingItem = true));
      try {
        runInAction(() => {
          this.newEditItem();
        });
        const item = (await axios.get<EI>(this.getEditItemUrl(id))).data;
        // console.log('item', item);
        runInAction(() => {
          this.editItem = item;
        });
        return true;
      } catch (error: any) {
        console.log('ERROR loading item for edit', error);
        toast.error('ERROR loading item for edit\r\n' + (error.message ?? JSON.stringify(error)));
        // toast.error(error.message ?? JSON.stringify(error));
      } finally {
        runInAction(() => (this.loadingItem = false));
      }
    }
    return false;
  };

  saveEditItem = async (showSuccess: boolean) => {
    try {
      const url = this.editItem.id === 0 ? this.getAddItemUrl() : this.getUpdateItemUrl(this.editItem.id);
      const item = this.prepareEditItemForPost(this.editItem, store.userStore.user?.id ?? 0);
      if (this.editItem.id === 0) {
        await axios.post(url, item);
        if (showSuccess) {
          toast.success(`Item successfully created`);
        }
        this.clear();
        await this.loadItems(true);
      } else {
        await axios.put(url, item);
        if (showSuccess) {
          toast.success(`Item successfully updated`);
        }
        this.clear();
        await this.loadItems(true);
      }
      return true;
    } catch (error: any) {
      console.log('ERROR saving item', error);
      toast.error('ERROR saving item\r\n' + (error.message ?? JSON.stringify(error)));
      // toast.error(error.message ?? JSON.stringify(error));
    } finally {
    }
    return false;
  };

  deleteItem = async (id: number, showSuccess: boolean) => {
    try {
      const url = this.getDeleteItemUrl(id);
      const ret = (await axios.delete(url)).data;
      console.log(ret);
      if (showSuccess) {
        toast.success(`Item with id=${id} was successfully deleted`);
      }
    } catch (error: any) {
      console.log('ERROR deletinging item', error);
      toast.error('ERROR deletinging item\r\n' + (error.message ?? JSON.stringify(error)));
      // toast.error(error.message ?? JSON.stringify(error));
    } finally {
    }
  };
}

export default BaseStore;
