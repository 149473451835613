interface CategoryParams {
  albums?: boolean;
  events?: boolean;
}

export const getNewCategoryParams = (albums: boolean = true, events: boolean = true): CategoryParams => {
  return {
    albums: albums,
    events: events,
  };
};

export default CategoryParams;
